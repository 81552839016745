<template>
  <div class="login">
    <div class="login-inner">
      <!-- Header -->
      <div v-if="action === 'ChangePassword'" class="block-header">
        <h1 class="title">Skift adgangskode</h1>
        <p class="desc">Indsæt din nye adgangskode to gange</p>
      </div>
      <div v-else-if="action === 'ForgotPassword'" class="block-header">
        <h1 class="title">Glemt adgangskode</h1>
        <p class="desc">
          Indsæt din email-adresse for at nulstille din adgangskode via et link
        </p>
      </div>
      <div v-else class="block-header">
        <h1 class="title">Log-ind</h1>
        <p class="desc">Indsæt email-adresse og adgangskode</p>
      </div>

      <!-- Form -->
      <form
        method="post"
        action=""
        autocomplete="off"
        novalidate="novalidate"
        v-on:submit.prevent
      >
        <!-- Email -->
        <div
          v-if="action === 'Login' || action === 'ForgotPassword'"
          class="control-input"
        >
          <input
            type="email"
            id="email"
            class="uik-input__input"
            placeholder="Email"
            v-model="email"
            autocomplete="email"
            required
          />
        </div>

        <!-- Password -->
        <div
          v-if="action === 'Login' || action === 'ChangePassword'"
          class="control-input"
        >
          <input
            type="password"
            id="password"
            class="uik-input__input"
            placeholder="Adgangskode"
            v-model="password"
            autocomplete="off"
            required
          />
        </div>

        <!-- Password 2 -->
        <div v-if="action === 'ChangePassword'" class="control-input">
          <input
            type="password"
            id="password2"
            class="uik-input__input"
            placeholder="Password again"
            v-model="password2"
            autocomplete="off"
            required
          />
        </div>

        <!-- Buttons -->
        <div class="form-footer">
          <button
            v-if="action === 'ChangePassword'"
            type="button"
            v-on:click="sendChangePassword"
            class="uik-btn__base btn btn-primary"
          >
            Skift adgangskode
          </button>
          <button
            v-else-if="action === 'ForgotPassword'"
            type="button"
            v-on:click="sendForgotPassword"
            class="uik-btn__base btn btn-primary"
          >
            Nulstil adgangskode
          </button>
          <button
            v-else-if="action === 'Login'"
            type="button"
            v-on:click="sendLogin"
            class="uik-btn__base btn btn-primary"
          >
            Log-ind
          </button>
        </div>
      </form>

      <!-- Footer -->
      <button
        v-if="action === 'ForgotPassword'"
        type="button"
        v-on:click="setActionLogin"
        class="uik-btn__link btn"
      >
        Gå til log-ind
      </button>
      <button
        v-else-if="action === 'Login'"
        type="button"
        v-on:click="setActionForgotPassword"
        class="uik-btn__link btn"
      >
        Glemt adgangskode?
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: function () {
    return {
      email: null,
      password: null,
      password2: null,
      token: null,
      action: "Login",
    };
  },
  created() {
    // Listen for password reset by query
    this.setActionChangePassword();
  },
  methods: {
    /**
     * Set action
     */
    setActionForgotPassword: function () {
      this.action = "ForgotPassword";
    },

    /**
     * Set action
     */
    setActionChangePassword: function () {
      if (
        this.$route.query.action === "ChangePassword" &&
        this.$route.query.token
      ) {
        this.action = "ChangePassword";
        this.token = this.$route.query.token;
      }
    },

    /**
     * Set action
     */
    setActionLogin: function () {
      this.action = "Login";
    },

    /**
     * Send forgot password
     */
    sendForgotPassword: function () {
      //  Validation
      if (!this.email) {
        this.$root.errorResponse("Missing email");
        return;
      }

      //  Request
      axios
        .post(
          this.$root.apiUrl +
            "/user/resetpassword?email=" +
            encodeURIComponent(this.email),
          null,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          //  Success
          this.$root.successResponse(
            "Email sent with reset link. Please check your inbox",
            response
          );
        })
        .catch((error) => {
          //  Error
          this.$root.handleErrorResponse(
            error,
            "Reset error. Please verify your email-address"
          );
        });
    },

    /**
     * Send change password
     */
    sendChangePassword: function () {
      //  Validation
      if (!this.password) {
        this.$root.errorResponse("Missing password");
        return;
      } else if (!this.password2) {
        this.$root.errorResponse("Missing password");
        return;
      } else if (this.password !== this.password2) {
        this.$root.errorResponse("Passwords are not the same");
        return;
      }

      //  Body
      let body = JSON.stringify({
        password: this.password,
      });

      //  Request
      axios
        .post(
          this.$root.apiUrl + "/user/changepassword?token=" + this.token,
          body,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          //  Success
          this.$root.successResponse("Password changed", response);

          //  Clean up
          this.password2 = null;
          this.$root.clearQuery();

          //  Prepare for login
          this.setActionLogin();
        })
        .catch((error) => {
          //  Error
          this.$root.handleErrorResponse(
            error,
            "Your change was not accepted. Please send a new password reset request."
          );
        });
    },

    /**
     * Send login
     */
    sendLogin: function () {
      //  Validation
      if (!this.email) {
        this.$root.errorResponse("Missing email");
        return;
      } else if (!this.password) {
        this.$root.errorResponse("Missing password");
        return;
      }

      //  Body
      let body = JSON.stringify({
        email: this.email,
        password: this.password,
      });

      //  Request
      axios
        .post(this.$root.apiUrl + "/user/login", body, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          //  Register
          this.$root.eventId = response.data.eventId;
          this.$root.user.authToken = response.data.authToken;

          //  Store authToken in browser session
          window.setMark("authToken", response.data.authToken);

          //  Set expiration
          var nowInSeconds = Math.round(new Date().getTime() / 1000);
          var oneDay = 24 * 3600;
          window.setMark("authExp", nowInSeconds + oneDay);

          //  Clean up
          this.password = null;

          //  Emit
          this.$emit("loggedIn");
        })
        .catch((error) => {
          //  Error
          this.$root.handleErrorResponse(error, "Invalid login. Please review");
        });
    },
  },
};
</script>
