<template>
  <tbody v-if="businessPayments.length > 0">
    <tr
      v-for="businessPayment in businessPayments"
      v-bind:key="businessPayment.businessPaymentId"
      v-bind:class="{
        blue:
          businessPayment.state === 'PENDING' ||
          (businessPayment.state === 'APPROVED' &&
            !businessPayment.updatedTs &&
            !businessPayment.requestedAnonymized),
        green: businessPayment.state === 'APPROVED',
        red: businessPayment.state === 'REJECTED',
      }"
    >
      <td class="manage-column column-created"></td>

      <!-- businessCode -->
      <td class="manage-column column-business-code">
        {{ businessPayment.businessCode }}
      </td>

      <!-- companyName -->
      <td
        class="manage-column column-business-name"
        v-bind:title="businessPayment.companyName"
      >
        {{ businessPayment.companyName }}
      </td>

      <!-- displayName -->
      <td class="manage-column column-display-name">
        {{ businessPayment.displayName }}
      </td>

      <!-- amount -->
      <td class="manage-column column-amount text-right">
        {{
          businessPayment.amount.toLocaleString("da-DK", {
            style: "currency",
            currency: "DKK",
          })
        }}
      </td>

      <!-- paymentMethodType -->
      <td class="manage-column column-payment-method-type">
        {{ businessPayment.paymentMethodType | formatPaymentMethodType }}
      </td>

      <!-- requestedAnonymized -->
      <td class="manage-column column-tv-crawler">
        <span v-if="businessPayment.requestedAnonymized === true">
          Anonym
        </span>
        <span
          v-else-if="
            businessPayment.showOnTvCrawler === true &&
            (businessPayment.state === 'PENDING' ||
              businessPayment.state === 'APPROVED')
          "
        >
          Vises på TV
        </span>
        <span
          v-else-if="
            businessPayment.state === 'APPROVED' &&
            !businessPayment.updatedTs &&
            !businessPayment.requestedAnonymized
          "
        >
          I kø
        </span>
        <span v-else-if="businessPayment.state === 'PENDING'"> I kø </span>
      </td>

      <!-- Actions -->
      <td class="manage-column column-action">
        <!-- Locked -->
        <button
          v-if="$root.administrator && businessPayment.claimTs"
          type="button"
          disabled="disabled"
          class="uik-btn__base btn btn-small btn-danger"
        >
          Låst
        </button>

        <!-- Locked -->
        <span v-else-if="businessPayment.claimTs">Behandles...</span>

        <!-- Edit rejected -->
        <button
          v-else-if="businessPayment.state === 'REJECTED'"
          type="button"
          class="uik-btn__base btn btn-small btn-approve"
          v-on:click="editBusinessPayment(businessPayment)"
        >
          Rediger
        </button>

        <!-- Edit validated -->
        <button
          v-else-if="
            businessPayment.state === 'APPROVED' && businessPayment.updatedTs
          "
          type="button"
          class="uik-btn__base btn btn-small btn-approve"
          v-on:click="editBusinessPayment(businessPayment)"
        >
          Rediger
        </button>

        <!-- Edit anything unlocked, if administrator -->
        <button
          v-else-if="$root.administrator"
          type="button"
          class="uik-btn__base btn btn-small btn-approve"
          v-on:click="editBusinessPayment(businessPayment)"
        >
          Rediger
        </button>
      </td>
    </tr>
  </tbody>
  <tbody v-else>
    <tr>
      <td colspan="8">Intet at liste...</td>
    </tr>
  </tbody>
</template>

<script>
import { formats } from "../../mixins/formats.js";
import axios from "axios";

export default {
  name: "BusinessPaymentTableBody",
  mixins: [formats],
  props: {
    businessPayments: {
      type: Array,
      required: true,
    },
  },
  methods: {
    editBusinessPayment(businessPayment) {
      //  Emit to force update
      this.$emit("edit", businessPayment);
    },

    /**
     * Update business payment
     * TODO : Change to emit (put)
     * @param {object} businessPayment
     * @param {string} state
     */
    async updateBusinessPayment(businessPayment, state) {
      //  Validate
      if (!businessPayment.amount) {
        this.$root.errorResponse("Missing amount");
        return;
      } else if (!businessPayment.displayName) {
        this.$root.errorResponse("Missing display name");
        return;
      }

      //  Set state
      businessPayment.state = state;

      //  Body
      let body = JSON.stringify(businessPayment);

      //  Request
      try {
        const response = await axios.put(
          this.$root.apiUrl +
            "/businesspayment/" +
            businessPayment.businessPaymentGuid,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );

        //  Update business payment
        businessPayment = response.data;

        //  Success
        this.$root.successResponse("Updated", response);

        //  Emit to force update
        this.$emit("updatedBusinessPayment", businessPayment);
      } catch (error) {
        //  Error
        this.$root.handleErrorResponse(error);
      }
    },
  },
};
</script>
