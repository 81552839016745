<template>
  <div class="profile-wrap">
    <div class="profile-bar">
      <div v-if="this.$root.user.name" class="avatar-wrap">
        <i class="dashboard-icon icon-profile"></i>{{ this.$root.user.name }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "NavbarTop",
});
</script>
