var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.businessPayments.length > 0)?_c('tbody',_vm._l((_vm.businessPayments),function(businessPayment){return _c('tr',{key:businessPayment.businessPaymentId,class:{
      blue:
        businessPayment.state === 'PENDING' ||
        (businessPayment.state === 'APPROVED' &&
          !businessPayment.updatedTs &&
          !businessPayment.requestedAnonymized),
      green: businessPayment.state === 'APPROVED',
      red: businessPayment.state === 'REJECTED',
    }},[_c('td',{staticClass:"manage-column column-created"}),_c('td',{staticClass:"manage-column column-business-code"},[_vm._v(" "+_vm._s(businessPayment.businessCode)+" ")]),_c('td',{staticClass:"manage-column column-business-name",attrs:{"title":businessPayment.companyName}},[_vm._v(" "+_vm._s(businessPayment.companyName)+" ")]),_c('td',{staticClass:"manage-column column-display-name"},[_vm._v(" "+_vm._s(businessPayment.displayName)+" ")]),_c('td',{staticClass:"manage-column column-amount text-right"},[_vm._v(" "+_vm._s(businessPayment.amount.toLocaleString("da-DK", { style: "currency", currency: "DKK", }))+" ")]),_c('td',{staticClass:"manage-column column-payment-method-type"},[_vm._v(" "+_vm._s(_vm._f("formatPaymentMethodType")(businessPayment.paymentMethodType))+" ")]),_c('td',{staticClass:"manage-column column-tv-crawler"},[(businessPayment.requestedAnonymized === true)?_c('span',[_vm._v(" Anonym ")]):(
          businessPayment.showOnTvCrawler === true &&
          (businessPayment.state === 'PENDING' ||
            businessPayment.state === 'APPROVED')
        )?_c('span',[_vm._v(" Vises på TV ")]):(
          businessPayment.state === 'APPROVED' &&
          !businessPayment.updatedTs &&
          !businessPayment.requestedAnonymized
        )?_c('span',[_vm._v(" I kø ")]):(businessPayment.state === 'PENDING')?_c('span',[_vm._v(" I kø ")]):_vm._e()]),_c('td',{staticClass:"manage-column column-action"},[(_vm.$root.administrator && businessPayment.claimTs)?_c('button',{staticClass:"uik-btn__base btn btn-small btn-danger",attrs:{"type":"button","disabled":"disabled"}},[_vm._v(" Låst ")]):(businessPayment.claimTs)?_c('span',[_vm._v("Behandles...")]):(businessPayment.state === 'REJECTED')?_c('button',{staticClass:"uik-btn__base btn btn-small btn-approve",attrs:{"type":"button"},on:{"click":function($event){return _vm.editBusinessPayment(businessPayment)}}},[_vm._v(" Rediger ")]):(
          businessPayment.state === 'APPROVED' && businessPayment.updatedTs
        )?_c('button',{staticClass:"uik-btn__base btn btn-small btn-approve",attrs:{"type":"button"},on:{"click":function($event){return _vm.editBusinessPayment(businessPayment)}}},[_vm._v(" Rediger ")]):(_vm.$root.administrator)?_c('button',{staticClass:"uik-btn__base btn btn-small btn-approve",attrs:{"type":"button"},on:{"click":function($event){return _vm.editBusinessPayment(businessPayment)}}},[_vm._v(" Rediger ")]):_vm._e()])])}),0):_c('tbody',[_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"8"}},[_vm._v("Intet at liste...")])])
}]

export { render, staticRenderFns }