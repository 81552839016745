<template>
  <thead>
    <tr>
      <th class="manage-column" style="width: 10px"></th>
      <th class="manage-column column-business-code">CVR-nr.</th>
      <th class="manage-column column-business-name">Navn</th>
      <th class="manage-column column-display-name">Visningsnavn</th>
      <th class="manage-column column-amount">Beløb</th>
      <th class="manage-column column-payment-method-type">Metode</th>
      <th class="manage-column column-tv-crawler">TV</th>
      <th class="manage-column column-action"></th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: "BusinessPaymentTableHead",
};
</script>
