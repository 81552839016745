<template>
  <div class="wrap list-payments">
    <div class="content">
      <!-- Back link -->
      <router-link
        v-bind:to="{ name: 'businesspayments' }"
        class="uik-btn__base btn"
        style="margin-left: 0"
        v-html="'< Tilbage'"
      ></router-link>
      <h1>Opret ny til optælling og visning på TV</h1>

      <!-- Editor -->
      <div class="box flex" style="padding: 15px 25px 7px">
        <table class="approval">
          <tr>
            <td>Vises på TV som:</td>
            <td style="width: 200px">Beløb</td>
            <td style="width: 100px"></td>
            <td style="width: 180px"></td>
            <td style="width: 100px"></td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                class="uik-input__input"
                placeholder="Visningsnavn"
                v-bind:maxlength="
                  this.$root.store.event.maxCrawlerDisplayLength
                "
                v-model="newBusinessPayment.displayName"
              />
            </td>
            <td>
              <input
                type="tel"
                class="uik-input__input"
                v-bind:min="this.$root.store.event.businessPaymentThreshold"
                v-model="newBusinessPayment.amount"
              />
            </td>
            <td>
              <label>
                <input
                  type="checkbox"
                  class="uik-input__input"
                  v-bind:value="true"
                  v-model="newBusinessPayment.showOnTvCrawler"
                />
                Vis på TV
                <div class="check"></div>
              </label>
            </td>
            <td>
              <label>
                <input
                  type="checkbox"
                  class="uik-input__input"
                  v-bind:value="true"
                  v-model="createCorrection"
                />
                Opret negativ korrektion
                <div class="check"></div>
              </label>
            </td>
            <td>
              <button
                type="button"
                class="uik-btn__base btn btn-primary"
                style="width: 100%"
                v-on:click="insertBusinessPayment"
              >
                <div class="lds-ring" :class="{ active: updateLoading }">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div style="margin: 0 10px">{{ buttonText }}</div>
              </button>
            </td>
          </tr>
          <tr>
            <td>
              (Max
              {{ this.$root.store.event.maxCrawlerDisplayLength }} karakterer)
            </td>
            <td>(Tælles med i totalen)</td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.content h1 {
  font-size: 26px;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

h1 {
  font-size: 26px;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

p {
  margin: 0 !important;
  min-height: 18px;
}

.approval {
  width: 100%;
}

.approval td:first-child {
  padding: 0 5px 0 0;
}

.approval td:last-child {
  padding: 0 0 0 5px;
}

.approval tr:last-child td {
  padding-top: 3px;
  color: rgba(0, 0, 0, 0.5);
}

.approval td {
  padding: 0 5px;
}

.approval td input {
  margin-top: 5px;
  font-size: 20px;
}

img {
  width: 20px;
  vertical-align: text-bottom;
  opacity: 0.75;
  margin-right: 15px;
}

.font-italic {
  opacity: 0.35;
  font-style: italic;
}

.shadow-text span {
  display: inline-block;
  height: 15px;
  background: linear-gradient(0.25turn, transparent, #eee, transparent),
    linear-gradient(#ddd, #ddd);
  background-repeat: no-repeat;
  background-size: 1800px 50px, 1800px 50px;
  background-position: -1800px 0, 0 0;
  animation: loading 2s infinite;
}

@keyframes loading {
  to {
    background-position: 1800px 0, 0 0;
  }
}
</style>

<script>
import axios from "axios";

export default {
  name: "BusinessPaymentCreate",
  data() {
    return {
      newBusinessPayment: {},
      createCorrection: true,
      updateLoading: false,
    };
  },
  created() {
    this.resetNewBusinessPayment();
  },
  computed: {
    buttonText() {
      if (this.updateLoading) {
        return "Opretter";
      }
      return "Opret";
    },
  },
  methods: {
    /**
     * Reset
     */
    resetNewBusinessPayment() {
      this.newBusinessPayment = {
        amount: null,
        businessCode: null,
        displayName: "",
        companyName: null,
        email: null,
        paymentMethodType: null,
        showOnTvCrawler: true,
        state: "APPROVED",
      };
    },

    /**
     * Insert
     */
    async insertBusinessPayment() {
      //  Validate initially
      if (!this.newBusinessPayment.amount) {
        this.$root.errorResponse("Beløb mangler");
        return;
      } else if (
        this.newBusinessPayment.amount <
        this.$root.store.event.businessPaymentThreshold
      ) {
        this.$root.errorResponse("Beløbet er under grænsen for visning på TV");
        return;
      } else if (!this.newBusinessPayment.displayName) {
        this.$root.errorResponse("Visningsnavn mangler");
        return;
      } else if (
        this.newBusinessPayment.displayName.length >
        this.$root.store.event.maxCrawlerDisplayLength
      ) {
        this.$root.errorResponse(
          "Visningsnavn er for langt. Efterse venligst."
        );
        return;
      }

      //  Verify
      if (
        confirm(
          "Du er ved at oprette nyt til optælling. Er du sikker? Beløb kan ikke ændres igen."
        )
      ) {
        //  Log
        window.console.log("Business payment: creating");

        //  Body
        let body = JSON.stringify(this.newBusinessPayment);

        //  Request
        try {
          const response = await axios.post(
            this.$root.apiUrl + "/businesspayment/",
            body,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.$root.user.authToken,
              },
            }
          );

          //  Create negative correction
          if (this.createCorrection === true) {
            this.insertNegativeCorrection(
              this.newBusinessPayment.amount,
              this.newBusinessPayment.displayName
            );
          }

          //  Update route
          this.$router.push({ name: "businesspayments" });

          //  Success
          this.$root.successResponse("Created", response);

          //  Log
          window.console.log(
            "Business payment: created (" +
              response.data.businessPaymentGuid +
              ")"
          );
        } catch (error) {
          //  Error
          this.$root.handleErrorResponse(error);

          //  Log
          window.console.log("Business payment: create failed");
        }
      }
    },

    /**
     * Insert negative correction
     */
    async insertNegativeCorrection(amount, description) {
      //  Negate amount
      amount = amount * -1;

      //  Body
      let body = JSON.stringify({
        amount: amount,
        description: "Oprettet via TV-Crawler: " + description,
        type: "BUSINESS_PAYMENT",
      });

      //  Request
      try {
        const response = await axios.post(
          this.$root.apiUrl + "/correction",
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );

        //  Log
        window.console.log(
          "Correction: created (" + response.data.correctionGuid + ")"
        );
      } catch (error) {
        //  Error
        this.$root.handleErrorResponse(error);

        //  Log
        window.console.log("Correction: create failed");
      }
    },
  },
};
</script>