//  Modules
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueToast from "vue-toast-notification";
import axios from "axios";
import VueApexCharts from 'vue-apexcharts'
import App from './App.vue';

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

//  Router
import Dashboard from './components/dashboard/Dashboard.vue';
import Corrections from './components/corrections/Corrections.vue';
import BusinessPayments from './components/businesspayments/BusinessPayments.vue';
import BusinessPaymentApproval from './components/businesspayments/BusinessPaymentApproval.vue';
import BusinessPaymentCreate from './components/businesspayments/BusinessPaymentCreate.vue';
import EventPayments from './components/eventpayments/EventPayments.vue';
import SmsCampaigns from './components/smscampaigns/SmsCampaigns.vue';

//  Assets
import "vue-toast-notification/dist/theme-default.css";
require('./assets/css/reset.min.css');
require('./assets/css/bootstrap-grid.min.css');
require('./assets/css/uik-core.css');
require('./assets/css/uik-lists.css');
require('./assets/css/uik-singles.css');
require('./assets/css/event-app.css');
require('./assets/js/event-app.js');

Vue.use(VueRouter);
Vue.use(VueToast, { position: "top-right", duration: 6000 });
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

// eslint-disable-next-line
const event_prefix = 'hjertegalla'; // 'hjertegalla'; // 'sammenforukraine'; // 'testevent';

// eslint-disable-next-line
const config_production = true;

// eslint-disable-next-line
const config_customer = false; // false

// eslint-disable-next-line
const config_administrator = true; // false

// eslint-disable-next-line
const config_api_url = (config_production ? 'https://event.api.v1.onlinefundraising.dk' : 'https://development.event.api.onlinefundraising.dk');

//  Vue production tips
Vue.config.productionTip = false;

//  Router configuration
const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
        { name: 'dashboard', path: '/', component: Dashboard },
        { name: 'corrections', path: '/corrections', component: Corrections },
        { name: 'businesspayments', path: '/businesspayments', component: BusinessPayments },
        { name: 'businesspaymentapproval', path: '/businesspayments/approval', component: BusinessPaymentApproval },
        { name: 'businesspaymentcreate', path: '/businesspayments/create', component: BusinessPaymentCreate },
        { name: 'eventpayments', path: '/eventpayments', component: EventPayments },
        { name: 'smscampaigns', path: '/smscampaigns', component: SmsCampaigns },
    ]
});

Sentry.init({
    Vue,
    dsn: "https://d7064c2fdde84502bdf6510558b10a29@sentry.fundraisingbureauet.dk/32",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['https://event.api.v1.onlinefundraising.dk', /^\//],
        }),
    ],
    maxBreadcrumbs: 50,
    sampleRate: 1,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
});

new Vue({
    router,
    data: {
        production: (config_production ? true : false),
        customer: (config_customer ? true : false),
        administrator: (config_administrator ? true : false),
        apiUrl: config_api_url,
        siteUrl: 'https://' + event_prefix + '.onlinefundraising.dk',
        callCenterUrl: 'https://' + event_prefix + '.callcenter.onlinefundraising.dk',
        tvCrawlerUrl: 'http://' + event_prefix + '.tvcrawler.onlinefundraising.dk',
        refreshInterval: 60000, // one minute
        refreshTypesCountInterval: 5000, // 5 secs
        eventId: null,
        user: {
            authToken: null,
            userGuid: null,
            name: null
        },
        store: {}
    },
    created() {
        this.getUser();
    },
    methods: {

        /**
         * Get user as validation of login
         */
        async getUser() {

            this.checkLogin();

            if (this.user.authToken) {

                window.console.log("account verifying");

                //  Request
                try {
                    const response = await axios
                        .get(this.apiUrl + "/user", {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: this.user.authToken
                            }
                        });
                    this.handleAccountResponse(response.data);
                } catch (error) {
                    this.handleAccountError(error);
                }
            }
        },

        /**
         * Check authToken and authExp from browser session for an initial login validation
         */
        checkLogin() {
            var nowInSeconds = Math.round(new Date().getTime() / 1000);

            let authExp = window.getMark("authExp");
            if (authExp && nowInSeconds > authExp) {

                //  Force logout
                this.logOut();
                window.console.log("login expired");

                this.$root.errorResponse("Login has expired. Please login again");
            }

            //  Transfer token
            else if (!this.user.authToken) {
                this.user.authToken = window.getMark("authToken");
            }
        },

        /**
         * Log out
         */
        logOut() {

            this.$root.apiUrl = config_api_url;
            this.$root.eventId = null;
            this.$root.user = {
                authToken: null,
                userGuid: null,
                name: null
            };

            //  Empty authToken and authExp in browser session
            window.setMark("authToken", null);
            window.setMark("authExp", null);
        },

        /**
         * Handle response from account
         * @param {object} userData
         */
        handleAccountResponse(userData) {

            //  Extract details
            this.user.name = userData.name;
            this.user.userGuid = userData.userGuid;

            //  Update expiration
            var nowInSeconds = Math.round(new Date().getTime() / 1000);
            var oneDay = 24 * 3600;
            window.setMark("authExp", nowInSeconds + oneDay);

            window.console.log("account verified");
        },

        /**
         * Handle error from account
         * @param {object} error 
         */
        handleAccountError(error) {

            this.logOut();
            window.console.log("login not verified");

            this.$root.handleErrorResponse(error, "Unverified access. Please login");
        },

        /**
         * Handle error response
         * @param {object} error 
         * @param {string} fallbackErrorMessage 
         */
        handleErrorResponse(error, fallbackErrorMessage = null) {

            var errorMessage;

            if (error.response && error.response.status === 503) {
                errorMessage =
                    "Service is temporarily unavailable. Please try again later";
            } else if (error.response && error.response.data.errorDescription) {
                errorMessage = error.response.data.errorDescription;
            } else if (fallbackErrorMessage !== null) {
                errorMessage = fallbackErrorMessage;
            } else if (error.response && error.response.status === 400 && error.response.data) {
                errorMessage = error.response.data;
            } else {
                errorMessage = "Something went wrong. Please try again later";
            }

            this.errorResponse(errorMessage);

            return errorMessage;
        },

        /**
         * Toast error message
         * @param {string} errorMessage 
         */
        errorResponse(errorMessage) {
            Vue.$toast.open({
                message: errorMessage,
                type: "error"
            });
        },

        /**
         * Toast info message
         * @param {string} infoMessage
         */
        infoResponse(infoMessage) {
            Vue.$toast.open({
                message: infoMessage,
                type: "info"
            });
        },

        /**
         * Toast success message
         * @param {string} successMessage
         */
        successResponse(successMessage) {
            Vue.$toast.open({
                message: successMessage,
                type: "success"
            });
        },

        /**
         * Clear query
         */
        clearQuery() {
            this.$router.replace({ 'query': null });
        }
    },
    render: h => h(App),
}).$mount('#event_app')