<template>
  <div class="wrap list-payments">
    <!-- Check permission -->
    <div
      v-if="!this.$root.administrator && !this.$root.customer"
      class="content"
    >
      <!-- Heading -->
      <h2 class="title">Betalinger via AP</h2>
      <p>Du har ikke adgang til denne side.</p>
    </div>

    <div v-else class="content">
      <!-- Heading -->
      <h2 class="title">Betalinger via API</h2>
      <p>
        Følgende donationer er modtaget via
        <a
          href="https://event.api.v1.onlinefundraising.dk/swagger-ui/"
          target="_blank"
          title="Se API-dokumentationen"
          >API</a
        >
        fra hjemmesiden.
      </p>

      <!-- Search -->
      <div class="search-payments">
        <input
          type="text"
          class="uik-input__input"
          placeholder="Søg på transaktions ID"
          v-model="searchEventPaymentsString"
          v-on:keyup="searchEventPayment"
        />
      </div>

      <!-- Table -->
      <div class="uik-entity-list businesspayment-list">
        <table>
          <!-- Table header -->
          <thead>
            <tr>
              <th class="manage-column" style="width: 190px">Tidspunkt</th>
              <th class="manage-column column-id" style="width: 150px">
                Transaktions ID
              </th>
              <th class="manage-column text-align-right" style="width: 150px">
                Beløb
              </th>
              <th
                class="manage-column column-payment-method-type"
                style="width: 100px"
              >
                Metode
              </th>
              <th class="manage-column">Kommentar</th>
              <th
                class="manage-column text-align-right"
                style="width: 75px"
              ></th>
            </tr>
          </thead>

          <!-- Error -->
          <tbody v-if="this.listErrored">
            <tr>
              <td colspan="6" class="text-center">Fejl ...</td>
            </tr>
          </tbody>

          <!-- Loading -->
          <tbody v-else-if="this.listLoading">
            <tr>
              <td colspan="6" class="text-center">Indlæser ...</td>
            </tr>
          </tbody>

          <!-- Table body -->
          <tbody v-else-if="this.eventPayments.length > 0">
            <tr
              v-for="eventPayment in this.eventPayments"
              v-bind:key="eventPayment.eventPaymentGuid"
            >
              <td
                v-bind:inner-html.prop="eventPayment.chargedTs | formatTime"
              ></td>
              <td v-bind:title="eventPayment.transactionId">
                {{ eventPayment.transactionId }}
              </td>
              <td
                class="text-align-right"
                v-bind:inner-html.prop="eventPayment.amount | formatAmount"
                v-bind:title="eventPayment.amount"
              ></td>
              <td
                class="column-payment-method-type"
                v-bind:title="eventPayment.paymentMethodType"
              >
                {{ eventPayment.paymentMethodType }}
              </td>
              <td v-bind:title="eventPayment.comment">
                {{ eventPayment.comment }}
              </td>
              <td class="text-align-right">
                <div
                  class="uik-btn__base btn btn-tiny"
                  v-on:click="archiveEventPayment(eventPayment)"
                >
                  Arkivér
                </div>
              </td>
            </tr>
          </tbody>

          <!-- Not found -->
          <tbody v-else>
            <tr>
              <td colspan="6" class="text-center">Ikke fundet ...</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <pagination
        v-bind:pageNumber="this.eventPageNumber"
        v-bind:pageSize="eventPageSize"
        v-bind:currentPageSize="this.eventPayments.length"
        v-on:setPage="setPage"
      />
    </div>
  </div>
</template>

<script>
import { formats } from "../../mixins/formats.js";
import Pagination from "../Pagination";
import axios from "axios";

export default {
  mixins: [formats],
  components: {
    Pagination,
  },
  data: function () {
    return {
      searchEventPaymentsString: "",
      searchTimeout: null,
      eventPayments: [],
      eventPageNumber: 1,
      eventPageSize: 20,
      listErrored: false,
      listLoading: true,
    };
  },
  created() {
    //  Get list
    this.getEventPayments();
  },
  methods: {
    /**
     * Get event payments
     */
    getEventPayments() {
      //  Reset list
      this.listLoading = true;
      this.eventPayments = [];

      //  Request
      return axios
        .get(
          this.$root.apiUrl +
            "/eventPayments?pageNumber=" +
            this.eventPageNumber +
            "&pageSize=" +
            this.eventPageSize,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        )
        .then((response) => {
          this.listLoading = false;
          this.listErrored = false;
          this.eventPayments = response.data;
        })
        .catch((error) => {
          this.listLoading = false;
          this.listErrored = true;

          //  Error
          this.$root.handleErrorResponse(error);
        });
    },

    /**
     * Search event payment
     */
    searchEventPayment() {
      if (this.searchEventPaymentsString) {
        //  Clear
        clearTimeout(this.searchTimeout);

        var self = this;

        //  Search debounced
        this.searchTimeout = setTimeout(function () {
          //  window.console.log("Searching: " + self.searchEventPaymentsString);

          self.listLoading = true;
          self.eventPayments = [];

          //  Request
          return axios
            .get(
              self.$root.apiUrl +
                "/eventPayments/search?query=" +
                self.searchEventPaymentsString,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: self.$root.user.authToken,
                },
              }
            )
            .then((response) => {
              self.listLoading = false;
              self.listErrored = false;
              self.eventPayments = response.data;
            })
            .catch((error) => {
              self.listLoading = false;
              self.listErrored = true;

              //  Error
              self.$root.handleErrorResponse(error);
            });
        }, 1000);
      } else {
        this.getEventPayments();
      }
    },

    /**
     * Archive event payment
     * @param {object} eventPayment
     */
    async archiveEventPayment(eventPayment) {
      //  Verify
      if (confirm("Du er ved at arkivere. Er du sikker?")) {
        //  Request
        try {
          await axios.post(
            this.$root.apiUrl +
              "/eventPayment/" +
              eventPayment.eventPaymentGuid +
              "/archive",
            null,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.$root.user.authToken,
              },
            }
          );
          this.getEventPayments();
        } catch (error) {
          //  Error
          this.$root.handleErrorResponse(error);
        }
      }
    },

    /**
     * Set pagination page number
     * @param {int} pageNumber
     */
    setPage(pageNumber) {
      this.eventPageNumber = pageNumber;
      this.getEventPayments();
    },
  },
};
</script>
