<template>
  <div class="wrap list-payments">
    <!-- Create -->
    <div class="create-business-payment">
      <router-link :to="{ name: 'businesspaymentcreate' }"
        ><img
          v-bind:src="require('@/assets/img/icons/document-create.png')"
          title="Opret ny til optælling og visning på TV"
      /></router-link>
    </div>
    <!-- Actions -->
    <div class="quick-nav">
      <router-link
        v-bind:to="{
          name: 'businesspaymentapproval',
          query: { type: 'pending' },
        }"
      >
        <div>Godkend</div>
        <div class="type">Faktura</div>
        <p>Godkend faktura til optælling og visningsnavn på TV.</p>
        <span>{{ `${businessPayments.pending.counter}` }}</span>
      </router-link>
      <router-link
        v-bind:to="{
          name: 'businesspaymentapproval',
          query: { type: 'approved' },
        }"
      >
        <div>Godkend</div>
        <div class="type">Visningsnavn</div>
        <p>Godkend til visning på TV.</p>
        <span class="shadow">{{ `${businessPayments.approved.counter}` }}</span>
      </router-link>
    </div>
    <div class="list-nav">
      <!-- Lists -->
      <div style="display: flex; align-items: end; margin-bottom: 2rem">
        <h2 style="font-size: 2rem; font-weight: normal; margin: 0 1rem 0 0">
          Overblik
        </h2>
        <!-- Search -->
        <div class="search-payments">
          <input
            type="text"
            class="uik-input__input"
            placeholder="Søg på visningsnavn"
            v-model="searchString"
            v-on:keyup="searchAllTypes"
          />
        </div>
      </div>

      <!-- List: PENDING -->
      <template>
        <h2
          class="title expander-title"
          v-bind:class="
            this.businessPayments.pending.expanded ? 'expanded' : null
          "
          v-on:click="expandList('pending')"
        >
          Kø for godkendelse af faktura og visningsnavn ({{
            businessPayments.pending.counter
          }})
          <span v-if="this.businessPayments.pending.expanded"
            >Luk og åben igen for at hente opdateret liste</span
          >
        </h2>

        <!-- EXPANDER -->
        <div
          class="expander"
          v-bind:class="
            this.businessPayments.pending.expanded ? 'expanded' : null
          "
        >
          <p v-if="this.businessPayments.pending.errored">Fejl ...</p>
          <template v-else>
            <p>
              Følgende fakturaer er i kø til godkendelse i forhold til
              optælling, og om de skal vises på TV. Behandl køen ved at klikke
              på "Faktura" ovenfor.
            </p>

            <!-- Table -->
            <div class="uik-entity-list businesspayment-list">
              <table
                v-on:mouseover="editingList = true"
                v-on:mouseleave="editingList = false"
              >
                <!-- Table head -->
                <business-payment-table-head />

                <!-- Table body -->
                <business-payment-table-body-simple
                  v-bind:businessPayments="this.businessPayments.pending.list"
                  v-on:updatedBusinessPayment="updatedBusinessPayment"
                  v-on:edit="claimSpecificBusinessPaymentAndEdit"
                />
              </table>

              <!-- Pagination -->
              <pagination
                v-bind:pageNumber="this.businessPayments.pending.page"
                v-bind:pageSize="listPageSize"
                v-bind:currentPageSize="
                  this.businessPayments.pending.list.length
                "
                v-on:setPage="setPendingList"
              />
            </div>
          </template>
        </div>
      </template>

      <!-- List: APPROVED -->
      <template>
        <h2
          class="title expander-title"
          v-bind:class="
            this.businessPayments.approved.expanded ? 'expanded' : null
          "
          v-on:click="expandList('approved')"
        >
          Kø for godkendelse af visningsnavn ({{
            businessPayments.approved.counter
          }})
          <span v-if="this.businessPayments.approved.expanded"
            >Luk og åben igen for at hente opdateret liste</span
          >
        </h2>
        <div
          class="expander"
          v-bind:class="
            this.businessPayments.approved.expanded ? 'expanded' : null
          "
        >
          <p v-if="this.businessPayments.approved.errroed">Fejl ...</p>
          <template v-else>
            <p>
              Følgende betalinger optælles, og er i kø til godkendelse inden de
              vises på TV. Behandl køen ved at klikke på "Visningsnavn" ovenfor.
            </p>

            <!-- Table -->
            <div class="uik-entity-list businesspayment-list">
              <table
                v-on:mouseover="editingList = true"
                v-on:mouseleave="editingList = false"
              >
                <!-- Table head -->
                <business-payment-table-head />

                <!-- Table body -->
                <business-payment-table-body-simple
                  v-bind:businessPayments="this.businessPayments.approved.list"
                  v-on:updatedBusinessPayment="updatedBusinessPayment"
                  v-on:edit="claimSpecificBusinessPaymentAndEdit"
                />
              </table>

              <!-- Pagination -->
              <pagination
                v-bind:pageNumber="this.businessPayments.approved.page"
                v-bind:pageSize="listPageSize"
                v-bind:currentPageSize="
                  this.businessPayments.approved.list.length
                "
                v-on:setPage="setApprovedList"
              />
            </div>
          </template>
        </div>
      </template>

      <!-- List: VALIDATED -->
      <template>
        <h2
          class="title expander-title"
          v-bind:class="
            this.businessPayments.validated.expanded ? 'expanded' : null
          "
          v-on:click="expandList('validated')"
        >
          Godkendte ({{ businessPayments.validated.counter }})
          <span v-if="this.businessPayments.validated.expanded"
            >Luk og åben igen for at hente opdateret liste</span
          >
        </h2>
        <div
          class="expander"
          v-bind:class="
            this.businessPayments.validated.expanded ? 'expanded' : null
          "
        >
          <p v-if="this.businessPayments.validated.errroed">Fejl ...</p>
          <template v-else>
            <p>Følgende bidrag er godkendt til optælling.</p>

            <!-- Table -->
            <div class="uik-entity-list businesspayment-list">
              <table
                v-on:mouseover="editingList = true"
                v-on:mouseleave="editingList = false"
              >
                <!-- Table head -->
                <business-payment-table-head />

                <!-- Table body -->
                <business-payment-table-body-simple
                  v-bind:businessPayments="this.businessPayments.validated.list"
                  v-on:updatedBusinessPayment="updatedBusinessPayment"
                  v-on:edit="claimSpecificBusinessPaymentAndEdit"
                />
              </table>

              <!-- Pagination -->
              <pagination
                v-bind:pageNumber="this.businessPayments.validated.page"
                v-bind:pageSize="listPageSize"
                v-bind:currentPageSize="
                  this.businessPayments.validated.list.length
                "
                v-on:setPage="setValidatedList"
              />
            </div>
          </template>
        </div>
      </template>

      <!-- List: REJECTED -->
      <template>
        <h2
          class="title expander-title"
          v-bind:class="
            this.businessPayments.rejected.expanded ? 'expanded' : null
          "
          v-on:click="expandList('rejected')"
        >
          Afviste ({{ businessPayments.rejected.counter }})
          <span v-if="this.businessPayments.rejected.expanded"
            >Luk og åben igen for at hente opdateret liste</span
          >
        </h2>
        <div
          class="expander"
          v-bind:class="
            this.businessPayments.rejected.expanded ? 'expanded' : null
          "
        >
          <p v-if="this.businessPayments.rejected.errroed">Fejl ...</p>
          <template v-else>
            <p>Følgende bidrag er afvist til optælling og visning på TV.</p>

            <!-- Table -->
            <div class="uik-entity-list businesspayment-list">
              <table
                v-on:mouseover="editingList = true"
                v-on:mouseleave="editingList = false"
              >
                <!-- Table head -->
                <business-payment-table-head />

                <!-- Table body -->
                <business-payment-table-body-simple
                  v-bind:businessPayments="this.businessPayments.rejected.list"
                  v-on:updatedBusinessPayment="updatedBusinessPayment"
                  v-on:edit="claimSpecificBusinessPaymentAndEdit"
                />
              </table>

              <!-- Pagination -->
              <pagination
                v-bind:pageNumber="this.businessPayments.rejected.page"
                v-bind:pageSize="listPageSize"
                v-bind:currentPageSize="
                  this.businessPayments.rejected.list.length
                "
                v-on:setPage="setRejectedList"
              />
            </div>
          </template>
        </div>
      </template>
    </div>

    <!-- Modal -->
    <div v-if="openBusinessPayment">
      <modal v-on:closeModal="closeEditBusinessPayment()">
        <business-payment-edit
          v-bind:businessPayment="businessPaymentToEdit"
          v-bind:loading="editBusinessPaymentLoading"
          v-bind:startTypesCounter="false"
          v-on:updated="editBusinessPaymentUpdated()"
        />
      </modal>
    </div>
  </div>
</template>

<style scoped>
.uik-entity-list table {
  margin-bottom: 15px;
}
.create-business-payment {
  position: fixed;
  top: 23px;
  right: 20px;
  z-index: 9999;
  opacity: 0.4;
}

.create-business-payment img {
  height: 28px;
  width: 28px;
}

.create-business-payment:hover {
  opacity: 0.7;
}

h2 span {
  font-size: 13px;
  color: #fff;
  background: #a5a5a580;
  padding: 0 1rem;
  border-radius: 7px;
  float: right;
}

.expander {
  margin-bottom: 40px;
}
</style>

<script>
import BusinessPaymentTableHead from "./BusinessPaymentTableHead.vue";
import BusinessPaymentTableBodySimple from "./BusinessPaymentTableBodySimple.vue";
import BusinessPaymentEdit from "./BusinessPaymentEdit.vue";
import Modal from "./../layout/modal/Modal.vue";
import Pagination from "../Pagination";
import axios from "axios";
import { formats } from "../../mixins/formats.js";

export default {
  name: "BusinessPayments",
  components: {
    BusinessPaymentTableHead,
    BusinessPaymentTableBodySimple,
    BusinessPaymentEdit,
    Modal,
    Pagination,
  },
  mixins: [formats],
  data: function () {
    return {
      searchString: "",
      searchStringLength: 3,
      listPageSize: 10,
      listErrored: false,
      listLoading: true,
      listChecker: null,
      typesCountChecker: null,
      editingList: false,
      newBusinessPayment: null,
      businessPaymentToEdit: null,
      editBusinessPaymentLoading: false,
      openBusinessPayment: false,
      businessPayments: {
        pending: {
          list: [],
          counter: 0,
          page: 1,
          expanded: false,
          loading: true,
          errored: false,
        },
        approved: {
          list: [],
          counter: 0,
          page: 1,
          expanded: false,
          loading: true,
          errored: false,
        },
        rejected: {
          list: [],
          counter: 0,
          page: 1,
          expanded: false,
          loading: true,
          errored: false,
        },
        validated: {
          list: [],
          counter: 0,
          page: 1,
          expanded: false,
          loading: true,
          errored: false,
        },
      },
    };
  },
  created() {
    //  Reset
    this.resetNewBusinessPayment();

    //  Get count
    this.getTypesCount();
    this.getTypesCountContinuously();
  },
  beforeDestroy() {
    clearInterval(this.listChecker);
    clearInterval(this.typesCountChecker);

    //  Stop refreshing list
    window.console.log("Business payments count: stop refreshing (general)");
  },
  methods: {
    /**
     * Claim and edit business payment
     */
    async claimSpecificBusinessPaymentAndEdit(businessPayment) {
      //  Reset list
      this.openBusinessPayment = true;
      this.editBusinessPaymentLoading = true;
      this.businessPaymentToEdit = null;

      let body = JSON.stringify({ claimId: window.getMarkSession("claimId") });

      //  Request
      try {
        const response = await axios.post(
          this.$root.apiUrl +
            "/businesspayment/" +
            businessPayment.businessPaymentGuid +
            "/claim",
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );

        //  Set business payment
        this.businessPaymentToEdit = response.data;

        //  Set showOnTvCrawler initially
        if (
          this.businessPaymentToEdit.updatedTs === null &&
          !this.businessPaymentToEdit.requestedAnonymized
        ) {
          this.businessPaymentToEdit.showOnTvCrawler = true;
        }

        //  Remember claimId
        window.setMarkSession("claimId", response.data.claimId);
      } catch (error) {
        //  Reset list
        this.openBusinessPayment = false;
        this.editBusinessPaymentLoading = false;
        this.businessPaymentToEdit = null;

        //  Error
        this.$root.handleErrorResponse(
          error,
          "Nogen er ved at redigere denne."
        );
      }
    },

    /**
     * Release business payment
     * TODO : Receive from emit (release)
     */
    async closeEditBusinessPayment() {
      let claimId = window.getMarkSession("claimId");
      let body = JSON.stringify({ claimId: claimId });

      console.log("Business payment: releasing (" + claimId + ")");

      //  Request
      try {
        await axios.post(
          this.$root.apiUrl +
            "/businesspayment/" +
            this.businessPaymentToEdit.businessPaymentGuid +
            "/release",
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );
        this.editBusinessPaymentLoading = false;
        this.openBusinessPayment = false;
        this.businessPaymentToEdit = null;
        console.log("Business payment: released (" + claimId + ")");
      } catch (e) {
        this.editBusinessPaymentLoading = false;
        this.openBusinessPayment = false;
        this.businessPaymentToEdit = null;
        console.log("Business payment: release failed (" + claimId + ")");
      }
    },

    /**
     * Reset new business payment
     */
    resetNewBusinessPayment() {
      this.newBusinessPayment = {
        amount: 0,
        businessCode: "",
        displayName: "",
        companyName: "",
        email: "",
        paymentMethodType: "",
        showOnTvCrawler: true,
        state: "APPROVED",
      };
    },

    /* Set pagination page number */
    setPendingList(pageNumber) {
      this.setList("pending", pageNumber);
    },
    setApprovedList(pageNumber) {
      this.setList("approved", pageNumber);
    },
    setValidatedList(pageNumber) {
      this.setList("validated", pageNumber);
    },
    setRejectedList(pageNumber) {
      this.setList("rejected", pageNumber);
    },
    setList(type, pageNumber) {
      this.businessPayments[type].page = pageNumber;
      this.getBusinessPayments(type);
    },
    expandList(type) {
      if (this.businessPayments[type].expanded) {
        this.businessPayments[type].list = [];
      }

      this.businessPayments[type].expanded =
        !this.businessPayments[type].expanded;

      if (
        this.businessPayments[type].expanded &&
        this.businessPayments[type].list.length === 0
      ) {
        this.getBusinessPayments(type);
      }
    },
    searchAllTypes() {
      if (this.searchString.length > 2) {
        this.businessPayments.pending.page = 1;
        this.businessPayments.approved.page = 1;
        this.businessPayments.validated.page = 1;
        this.businessPayments.rejected.page = 1;
        this.businessPayments.pending.expanded = true;
        this.businessPayments.approved.expanded = true;
        this.businessPayments.validated.expanded = true;
        this.businessPayments.rejected.expanded = true;
        this.searchBusinessPayments();
      } else if (this.searchString.length === 0) {
        this.businessPayments.pending.page = 1;
        this.businessPayments.approved.page = 1;
        this.businessPayments.validated.page = 1;
        this.businessPayments.rejected.page = 1;
        this.businessPayments.pending.expanded = false;
        this.businessPayments.approved.expanded = false;
        this.businessPayments.validated.expanded = false;
        this.businessPayments.rejected.expanded = false;
      }
    },

    /**
     * Get business payments
     * @param {string} state
     */
    async getBusinessPayments(type) {
      //  Reset list
      this.businessPayments[type].loading = true;

      //  Request
      try {
        const response = await axios.get(
          this.$root.apiUrl +
            "/businesspayments" +
            "?type=" +
            type.toUpperCase() +
            "&pageNumber=" +
            this.businessPayments[type].page +
            "&pageSize=" +
            this.listPageSize,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );
        this.businessPayments[type].loading = false;
        this.businessPayments[type].errored = false;
        this.businessPayments[type].list = response.data;
      } catch (error) {
        this.businessPayments[type].loading = false;
        this.businessPayments[type].errored = true;

        //  Error
        this.$root.handleErrorResponse(error);
      }
    },

    /**
     * Search business payments
     */
    async searchBusinessPayments() {
      // Loading
      this.businessPayments.pending.loading = true;
      this.businessPayments.approved.loading = true;
      this.businessPayments.validated.loading = true;
      this.businessPayments.rejected.loading = true;

      //  Request
      try {
        const response = await axios.get(
          this.$root.apiUrl +
            "/businesspayments/search?query=" +
            this.searchString,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );
        // Loading
        this.businessPayments.pending.loading = false;
        this.businessPayments.approved.loading = false;
        this.businessPayments.validated.loading = false;
        this.businessPayments.rejected.loading = false;
        // Errored
        this.businessPayments.pending.errored = false;
        this.businessPayments.approved.errored = false;
        this.businessPayments.validated.errored = false;
        this.businessPayments.rejected.errored = false;

        // Data
        this.businessPayments.pending.list = response.data.filter(
          (x) => x.state === "PENDING"
        );
        this.businessPayments.approved.list = response.data.filter(
          (x_1) =>
            x_1.state === "APPROVED" &&
            x_1.updatedTs === null &&
            !x_1.requestedAnonymized
        );
        this.businessPayments.validated.list = response.data.filter(
          (x_2) =>
            x_2.state === "APPROVED" &&
            (x_2.updatedTs !== null || x_2.requestedAnonymized === true)
        );
        this.businessPayments.rejected.list = response.data.filter(
          (x_3) => x_3.state === "REJECTED"
        );
      } catch (error) {
        // Loading
        this.businessPayments.pending.loading = false;
        this.businessPayments.approved.loading = false;
        this.businessPayments.validated.loading = false;
        this.businessPayments.rejected.loading = false;
        // Errored
        this.businessPayments.pending.errored = true;
        this.businessPayments.approved.errored = true;
        this.businessPayments.validated.errored = true;
        this.businessPayments.rejected.errored = true;

        //  Error
        this.$root.handleErrorResponse(error);
      }
    },

    /**
     * Get business payments continuously
     */
    getBusinessPaymentsContinuously() {
      this.listChecker = setInterval(
        function () {
          //  Skip if error
          if (this.listErrored) {
            window.console.log(
              "Business payments: refresh skipped due to error"
            );
          }

          //  Skip if editing
          else if (this.editingList) {
            window.console.log(
              "Business payments: refresh skipped due to editing"
            );
          }

          //  Request
          else {
            window.console.log("Business payments: refresh");
            this.getBusinessPayments();
          }
        }.bind(this),
        this.$root.refreshInterval
      );
    },

    /**
     * Insert business payment
     * TODO : Receive from emit (post)
     */
    async insertBusinessPayment() {
      //  Validate
      if (!this.newBusinessPayment.amount) {
        this.$root.errorResponse("Missing amount");
        return;
      } else if (!this.newBusinessPayment.displayName) {
        this.$root.errorResponse("Missing display name");
        return;
      } else if (!this.newBusinessPayment.state) {
        this.$root.errorResponse("Missing state");
        return;
      }

      //  Body
      let body = JSON.stringify(this.newBusinessPayment);

      //  Request
      try {
        const response = await axios.post(
          this.$root.apiUrl + "/businesspayment/",
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );

        //  Reset
        this.resetNewBusinessPayment();

        //  Refresh list
        this.getBusinessPayments();

        //  Success
        this.$root.successResponse("Created", response);
      } catch (error) {
        //  Error
        this.$root.handleErrorResponse(error);
      }
    },

    /**
     * Get types count
     */
    async getTypesCount() {
      //  Request
      const response = await axios.get(
        this.$root.apiUrl + "/businesspayments/count",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$root.user.authToken,
          },
        }
      );

      //  Set counts
      this.businessPayments.pending.counter = response.data.PENDING;
      this.businessPayments.approved.counter = response.data.APPROVED;
      this.businessPayments.rejected.counter = response.data.REJECTED;
      this.businessPayments.validated.counter = response.data.VALIDATED;
    },

    /**
     * Get types count continuously
     */
    getTypesCountContinuously() {
      window.console.log("Business payments count: start refreshing (general)");

      clearInterval(this.typesCountChecker);

      this.typesCountChecker = setInterval(
        function () {
          this.getTypesCount();
        }.bind(this),
        this.$root.refreshTypesCountInterval
      );
    },

    /**
     * Update business payment in existing list of business payments
     */
    updatedBusinessPayment(businessPayment) {
      let tempBusinessPayments = this.businessPayments;

      this.businessPayments = [];

      for (var i = 0; i < tempBusinessPayments.length; ++i) {
        if (
          tempBusinessPayments[i].businessPaymentGuid ===
          businessPayment.businessPaymentGuid
        ) {
          tempBusinessPayments[i] = businessPayment;
        }

        this.businessPayments.push(tempBusinessPayments[i]);
      }

      this.$forceUpdate();
    },

    /**
     * Business payment listener - reseting
     */
    editBusinessPaymentUpdated() {
      console.log("Business payments: business payment updated");
      this.editBusinessPaymentLoading = false;
      this.openBusinessPayment = false;
      this.businessPaymentToEdit = null;
      this.businessPayments.pending.expanded = false;
      this.businessPayments.approved.expanded = false;
      this.businessPayments.validated.expanded = false;
      this.businessPayments.rejected.expanded = false;
      this.businessPayments.pending.list = [];
      this.businessPayments.approved.list = [];
      this.businessPayments.validated.list = [];
      this.businessPayments.rejected.list = [];
    },
  },
};
</script>