<template>
  <div>
    <!-- Loading -->
    <template v-if="!businessPayment">
      <h1>{{ fetchingText }}</h1>
      <div
        class="grid grid-cols-2"
        style="font-size: 18px; margin-bottom: 80px; line-height: 30px"
      >
        <div class="box">
          <table>
            <tr>
              <td style="color: rgba(0, 0, 0, 0.6)">CVR-nr.:</td>
              <td style="width: 100%" class="shadow-text">
                <span style="width: 160px"></span>
              </td>
            </tr>
            <tr>
              <td style="color: rgba(0, 0, 0, 0.6); padding-right: 10px">
                Firmanavn:
              </td>
              <td style="width: 100%" class="shadow-text">
                <span style="width: 70%"></span>
              </td>
            </tr>
            <tr>
              <td style="color: rgba(0, 0, 0, 0.6); padding-right: 10px">
                Visningsnavn:
              </td>
              <td style="width: 100%" class="shadow-text">
                <span style="width: 70%"></span>
              </td>
            </tr>
            <tr>
              <td style="color: rgba(0, 0, 0, 0.6); padding-right: 10px">
                Metode:
              </td>
              <td style="width: 100%" class="shadow-text">
                <span style="width: 70%"></span>
              </td>
            </tr>
          </table>
        </div>
        <div class="box">
          <p>Kontakt</p>
          <p class="shadow-text">
            <img :src="require('@/assets/img/icons/icon-phone.png')" /><span
              style="width: 150px"
            ></span>
          </p>
          <p class="shadow-text">
            <img :src="require('@/assets/img/icons/icon-email.png')" /><span
              style="width: 280px"
            ></span>
          </p>
        </div>
      </div>
      <h1>Godkend</h1>
      <div class="box flex" style="padding: 15px 25px 7px">
        <table class="approval">
          <tr>
            <td>Vises på TV som:</td>
            <td style="width: 200px">Beløb</td>
            <td style="width: 150px"></td>
            <td style="width: 100px"></td>
          </tr>
          <tr>
            <td style="padding-top: 7px">
              <div class="shadow-text"><span style="width: 100%"></span></div>
            </td>
            <td class="shadow-text"><span style="width: 100%"></span></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              (Max
              {{ this.$root.store.event.maxCrawlerDisplayLength }} karakterer)
            </td>
            <td>(Tælles med i totalen)</td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </template>
    <!-- Loaded business payment -->
    <template v-else>
      <h1>{{ fetchingText }}</h1>
      <div
        class="grid grid-cols-2"
        style="font-size: 18px; margin-bottom: 80px; line-height: 30px"
      >
        <div class="box">
          <table>
            <tr>
              <td style="color: rgba(0, 0, 0, 0.6)">CVR-nr.:</td>
              <td
                v-bind:class="{ 'font-italic': !businessPayment.businessCode }"
              >
                {{ businessCode }}
                <a
                  v-if="businessPayment.businessCode"
                  v-bind:href="
                    'https://datacvr.virk.dk/enhed/virksomhed/' +
                    businessPayment.businessCode
                  "
                  target="_blank"
                  class="uik-btn__base btn btn-tiny"
                  title="Verificér virksomheden mod CVR-registeret"
                  >CVR <i class="fa fa-external-link"></i
                ></a>
              </td>
              <td></td>
            </tr>
            <tr>
              <td style="color: rgba(0, 0, 0, 0.6); padding-right: 10px">
                Firmanavn:
              </td>
              <td>
                <span :class="{ 'font-italic': !businessPayment.companyName }">
                  {{ companyName }}
                </span>
                <a
                  v-if="businessPayment.dataSetGuid"
                  v-bind:href="
                    $root.siteUrl +
                    '/wp-admin/admin.php?page=data_sets&data_set=' +
                    businessPayment.dataSetGuid
                  "
                  target="_blank"
                  class="uik-btn__base btn btn-tiny"
                  title="Se DataSet i OnlineFundraising"
                  >Data <i class="fa fa-external-link"></i
                ></a>
                <a
                  v-if="businessPayment.paymentGuid"
                  v-bind:href="
                    $root.siteUrl +
                    '/wp-admin/admin.php?page=payments&payment=' +
                    businessPayment.paymentGuid
                  "
                  target="_blank"
                  class="uik-btn__base btn btn-tiny"
                  title="Se Payment i OnlineFundraising"
                  >Payment <i class="fa fa-external-link"></i
                ></a>
              </td>
              <td></td>
            </tr>
            <tr>
              <td style="color: rgba(0, 0, 0, 0.6); padding-right: 10px">
                Visningsnavn:
              </td>
              <td
                v-bind:class="{ 'font-italic': !businessPayment.displayName }"
              >
                {{ businessPayment.displayName }}
                <a
                  v-bind:href="
                    'https://www.google.com/search?&q=%22' +
                    encodeURIComponent(businessPayment.displayName) +
                    '%22'
                  "
                  target="_blank"
                  class="uik-btn__base btn btn-tiny"
                  title="Søg på Google"
                  >Google <i class="fa fa-external-link"></i
                ></a>
              </td>
              <td></td>
            </tr>
            <tr>
              <td style="color: rgba(0, 0, 0, 0.6); padding-right: 10px">
                Metode:
              </td>
              <td>
                {{
                  businessPayment.paymentMethodType | formatPaymentMethodType
                }}
              </td>
              <td></td>
            </tr>
          </table>
        </div>
        <div class="box">
          <p>Kontakt</p>
          <p>
            <img v-bind:src="require('@/assets/img/icons/icon-phone.png')" />
            <span v-if="!businessPayment.msisdn" class="font-italic"
              >Ikke angivet</span
            >
            <a
              v-else
              v-bind:href="`callto:${businessPayment.msisdn}`"
              target="_blank"
              rel="noopener noreferrer"
              v-bind:inner-html.prop="businessPayment.msisdn | formatMsisdn"
            ></a>
          </p>
          <p>
            <img v-bind:src="require('@/assets/img/icons/icon-email.png')" />
            <span v-if="!businessPayment.email" class="font-italic"
              >Ikke angivet</span
            >
            <a v-else v-bind:href="`mailto:${businessPayment.email}`">{{
              businessPayment.email
            }}</a>
          </p>
        </div>
      </div>
      <h1>{{ typeText }}</h1>
      <div class="box flex" style="padding: 15px 25px 7px">
        <table class="approval">
          <tr>
            <td>Vises på tv som:</td>
            <td style="width: 200px">Beløb</td>
            <td style="width: 150px"></td>
            <td style="width: 100px"></td>
            <td v-if="activeType === 'pending'" style="width: 100px"></td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                class="uik-input__input"
                placeholder="Visningsnavn"
                v-bind:maxlength="
                  this.$root.store.event.maxCrawlerDisplayLength
                "
                v-model="businessPayment.displayName"
              />
            </td>
            <td>
              <!-- Changed after Hjertegalla 2022 to allow the client to update the amount
              <span v-if="activeType !== 'pending'">
                {{
                  businessPayment.amount.toLocaleString("da-DK", {
                    style: "currency",
                    currency: "DKK",
                  })
                }}
              </span>
              <input
                v-else
                type="tel"
                class="uik-input__input"
                v-bind:disabled="activeType !== 'pending'"
                v-model="businessPayment.amount"
              />
              -->
              <input
                type="tel"
                class="uik-input__input"
                v-model="businessPayment.amount"
              />
            </td>
            <td>
              <span v-if="businessPayment.requestedAnonymized === true"
                >Anonym</span
              >
              <label v-else>
                <input
                  type="checkbox"
                  class="uik-input__input"
                  v-bind:value="true"
                  v-model="businessPayment.showOnTvCrawler"
                />
                Vis på TV
                <div class="check"></div>
              </label>
            </td>
            <td>
              <button
                type="button"
                class="uik-btn__base btn btn-primary"
                style="width: 100%"
                v-bind:disabled="updateLoading || isButtonsDisabled"
                v-on:click="updateBusinessPayment(false)"
              >
                <div class="lds-ring" :class="{ active: updateLoading }">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div style="margin: 0 10px">{{ buttonText }}</div>
              </button>
            </td>
            <td v-if="activeType === 'pending'">
              <button
                type="button"
                class="uik-btn__base btn"
                style="width: 100%"
                v-bind:disabled="updateLoading || isButtonsDisabled"
                v-on:click="updateBusinessPayment(true)"
              >
                <div class="lds-ring" :class="{ active: updateLoading }">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div style="margin: 0 10px">{{ buttonRejectText }}</div>
              </button>
            </td>
          </tr>
          <tr>
            <td>
              (Max
              {{ this.$root.store.event.maxCrawlerDisplayLength }} karakterer)
            </td>
            <td>(Tælles med i totalen)</td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </template>
  </div>
</template>


<style scoped>
.content h1 {
  font-size: 26px;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

h1 {
  font-size: 26px;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

p {
  margin: 0 !important;
  min-height: 18px;
}

.approval {
  width: 100%;
}

.approval td:first-child {
  padding: 0 5px 0 0;
}

.approval td:last-child {
  padding: 0 0 0 5px;
}

.approval tr:last-child td {
  padding-top: 3px;
  color: rgba(0, 0, 0, 0.5);
}

.approval td {
  padding: 0 5px;
}

.approval td input {
  margin-top: 5px;
  font-size: 20px;
}
img {
  width: 20px;
  vertical-align: text-bottom;
  opacity: 0.75;
  margin-right: 15px;
}

.font-italic {
  opacity: 0.35;
  font-style: italic;
}

.shadow-text span {
  display: inline-block;
  height: 15px;
  background: linear-gradient(0.25turn, transparent, #eee, transparent),
    linear-gradient(#ddd, #ddd);
  background-repeat: no-repeat;
  background-size: 1800px 50px, 1800px 50px;
  background-position: -1800px 0, 0 0;
  animation: loading 2s infinite;
}

@keyframes loading {
  to {
    background-position: 1800px 0, 0 0;
  }
}
</style>

<script>
import axios from "axios";
import { formats } from "../../mixins/formats.js";

export default {
  name: "BusinessPaymentEdit",
  props: {
    loading: Boolean,
    businessPayment: {
      type: Object,
    },
    businessPaymentType: String,
    startTypesCounter: Boolean,
  },
  mixins: [formats],
  data() {
    return {
      typesCountInterval: null,
      countDownInterval: null,
      secondsUntilRefreshTypesCount: this.$root.refreshTypesCountInterval,
      updateLoading: false,
      count: null,
      isButtonsDisabled: true,
      disabledSecondsRemaining: 3,
      disabledSecondsTotal: 3,
    };
  },
  watch: {
    businessPayment: {
      immediate: true,
      handler(val) {
        console.log("Watch: Business payment: " + val);
        if (typeof val === "object" && val !== null) {
          console.log("Watch: Start button disabled timer");
          this.startButtonDisabledTimer();
        }
      },
    },
  },
  computed: {
    activeType() {
      if (this.businessPaymentType) {
        return this.businessPaymentType;
      } else if (this.businessPayment.state === "PENDING") {
        return "pending";
      } else if (
        this.businessPayment.state === "APPROVED" &&
        this.businessPayment.updatedTs === null &&
        !this.businessPayment.requestedAnonymized
      ) {
        return "approved";
      } else if (
        this.businessPayment.state === "APPROVED" &&
        (this.businessPayment.updatedTs !== null ||
          this.businessPayment.requestedAnonymized === true)
      ) {
        return "verified";
      }

      //  Default
      return "rejected";
    },
    secondsUntilNextCount() {
      return this.secondsUntilRefreshTypesCount / 1000 - 1;
    },
    buttonText() {
      if (this.isButtonsDisabled) {
        return `Låst (${this.disabledSecondsRemaining})`;
      }
      if (this.activeType === "pending" || this.activeType === "rejected") {
        if (this.updateLoading) {
          return "Godkender";
        }
        return "Godkend";
      } else if (this.activeType === "approved") {
        if (this.updateLoading) {
          return "Godkender";
        }
        return "Godkend";
      } else if (this.activeType === "verified") {
        if (this.updateLoading) {
          return "Opdaterer";
        }
        return "Opdater";
      }

      if (this.updateLoading) {
        return "Opdaterer";
      }

      return "Opdater";
    },
    buttonRejectText() {
      if (this.updateLoading) {
        return "Afviser";
      }
      return "Afvis";
    },
    businessCode() {
      if (this.businessPayment.businessCode) {
        return this.businessPayment.businessCode;
      }
      return "Ikke angivet";
    },
    organisationName() {
      if (this.businessPayment.organisationName) {
        return this.businessPayment.organisationName;
      }
      return "Ikke angivet";
    },
    companyName() {
      if (this.businessPayment.companyName) {
        return this.businessPayment.companyName;
      }
      return "Ikke angivet";
    },
    fetchingText() {
      if (this.businessPayment) {
        return "Oplysninger om donor";
      }
      return "Henter data";
    },
    type() {
      if (this.businessPaymentType === "pending") {
        return "PENDING";
      } else {
        return "APPROVED";
      }
    },
    typeText() {
      if (this.businessPaymentType === "pending") {
        return "Godkend faktura og visningsnavn til TV";
      } else if (this.businessPaymentType === "approved") {
        return "Godkend visningsnavn til TV";
      } else {
        return "Rediger";
      }
    },
  },
  methods: {
    startButtonDisabledTimer() {
      if (this.disabledSecondsRemaining === 0) {
        console.log("End button disabled timer");
        this.disabledSecondsRemaining = this.disabledSecondsTotal;
        this.isButtonsDisabled = false;
      } else {
        this.isButtonsDisabled = true;
        setTimeout(
          function () {
            this.disabledSecondsRemaining -= 1;
            this.startButtonDisabledTimer();
          }.bind(this),
          1000
        );
      }
    },

    /**
     *  Countdown seconds
     */
    secondsUntilRefreshTypesCountInterval() {
      this.countDownInterval = setInterval(
        function () {
          if (this.secondsUntilRefreshTypesCount > 0) {
            this.secondsUntilRefreshTypesCount -= 1000;
          }
        }.bind(this),
        1000
      );
    },
    resetSecondsUntilRefreshTypesCountInterval() {
      clearInterval(this.countDownInterval);
      this.secondsUntilRefreshTypesCount = this.$root.refreshTypesCountInterval;
      this.secondsUntilRefreshTypesCountInterval();
    },

    /**
     * Update business payment
     * TODO : Change to emit (put)
     */
    async updateBusinessPayment(isReject) {
      //  Validate
      if (!this.businessPayment.amount) {
        this.$root.errorResponse("Beløb mangler");
        return;
      } else if (!this.businessPayment.displayName) {
        this.$root.errorResponse("Visningsnavn mangler");
        return;
      } else if (
        this.businessPayment.displayName.length >
        this.$root.store.event.maxCrawlerDisplayLength
      ) {
        this.$root.errorResponse(
          "Visningsnavn er for langt. Efterse venligst."
        );
        return;
      }

      this.updateLoading = true;

      //  Set state
      if (isReject) {
        this.businessPayment.state = "REJECTED";
      } else if (
        this.businessPayment.state === "REJECTED" ||
        this.businessPayment.state === "PENDING"
      ) {
        this.businessPayment.state = "APPROVED";
      }

      //  Body
      let claimId = window.getMarkSession("claimId");
      let body = JSON.stringify({
        updateDto: this.businessPayment,
        claim: {
          claimId: claimId,
        },
      });

      window.console.log("Business payment: updating (" + claimId + ")");

      //  Request
      try {
        const response = await axios.put(
          this.$root.apiUrl +
            "/businesspayment/" +
            this.businessPayment.businessPaymentGuid,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );

        //  Release
        this.releaseBusinessPayment();
        this.$emit("updated", this.businessPayment);
        this.updateLoading = false;

        //  Success
        if (this.businessPayment.state === "REJECTED") {
          this.$root.successResponse("Afvist", response);
        } else {
          this.$root.successResponse("Opdateret", response);
        }
        window.console.log("Business payment: updated (" + claimId + ")");
      } catch (error) {
        this.updateLoading = false;

        //  Error
        this.$root.handleErrorResponse(error);
        window.console.log("Business payment: update failed (" + claimId + ")");
      }
    },

    /**
     * Release business payment
     * TODO : Change to emit (release)
     */
    async releaseBusinessPayment() {
      let body = JSON.stringify({ claimId: window.getMarkSession("claimId") });

      //  Request
      try {
        await axios.post(
          this.$root.apiUrl +
            "/businesspayment/" +
            this.businessPayment.businessPaymentGuid +
            "/release",
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );
        this.editBusinessPaymentLoading = false;
        this.openBusinessPayment = false;
        this.businessPaymentToEdit = null;
      } catch (e) {
        this.editBusinessPaymentLoading = false;
        this.openBusinessPayment = false;
        this.businessPaymentToEdit = null;
      }
    },
  },
};
</script>