<template>
  <div class="wrap list-payments">
    <div class="content">
      <!-- Back link -->
      <router-link
        v-bind:to="{ name: 'businesspayments' }"
        class="uik-btn__base btn"
        style="margin-left: 0"
        v-html="'< Tilbage'"
      ></router-link>

      <!-- Counter -->
      <div class="count-in-line">{{ count }} i køen</div>

      <!-- Empty queue -->
      <template v-if="!businessPayment && count === 0">
        <div class="no-more">
          <h1>Køen er tom</h1>
          <p>Godt klaret!</p>
          <span>Checker igen om {{ secondsUntilNextCount }} sekunder</span>
        </div>
      </template>

      <!-- Editor -->
      <business-payment-edit
        v-else
        v-bind:businessPayment="businessPayment"
        v-bind:loading="loading"
        v-bind:businessPaymentType="this.$route.query.type"
        v-bind:startTypesCounter="true"
        v-on:updated="businessPaymentUpdated()"
      />
    </div>
  </div>
</template>

<style scoped>
.count-in-line {
  position: absolute;
  top: 25px;
  right: 35px;
  font-size: 18px;
}

.no-more {
  text-align: center;
}

.no-more h1 {
  margin-top: 100px;
  font-size: 6rem;
  line-height: normal;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.5);
}

.no-more p {
  font-size: 1.7rem;
  line-height: normal;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.35);
}

.no-more span {
  font-size: 1.2rem;
  line-height: normal;
  color: rgba(0, 0, 0, 0.35);
}

@keyframes loading {
  to {
    background-position: 1800px 0, 0 0;
  }
}
</style>

<script>
import axios from "axios";
import businessPaymentEdit from "./BusinessPaymentEdit.vue";

export default {
  name: "BusinessPaymentApproval",
  components: {
    businessPaymentEdit,
  },
  data() {
    return {
      loading: false,
      businessPayment: null,
      typesCountInterval: null,
      countDownInterval: null,
      secondsUntilRefreshTypesCount: this.$root.refreshTypesCountInterval,
      updateLoading: false,
      count: null,
    };
  },
  computed: {
    secondsUntilNextCount() {
      return this.secondsUntilRefreshTypesCount / 1000 - 1;
    },
    type() {
      if (this.$route.query.type === "pending") {
        return "PENDING";
      } else {
        return "APPROVED";
      }
    },
  },
  created() {
    //  Get count
    this.getTypesCount();
    this.getTypesCountContinuously();
  },
  destroyed() {
    clearInterval(this.typesCountInterval);
    this.releaseBusinessPayment();

    //  Stop refreshing list
    window.console.log("Business payments count: stop refreshing (approval)");
  },
  methods: {
    /**
     * Get types count
     */
    getTypesCount() {
      //  Request
      return axios
        .get(this.$root.apiUrl + "/businesspayments/count", {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$root.user.authToken,
          },
        })
        .then((response) => {
          if (this.type === "APPROVED") {
            this.count = response.data.APPROVED;
          } else {
            this.count = response.data.PENDING;
          }

          // Hvis der findes types
          if (this.count > 0 && !this.businessPayment) {
            this.getClaimedBusinessPayment();
          }

          this.resetSecondsUntilRefreshTypesCountInterval();
        })
        .catch(() => {
          this.resetSecondsUntilRefreshTypesCountInterval();
        });
    },

    /**
     * Start continuous refresh
     */
    getTypesCountContinuously() {
      window.console.log(
        "Business payments count: start refreshing (approval)"
      );

      clearInterval(this.typesCountInterval);

      this.typesCountInterval = setInterval(
        function () {
          this.getTypesCount();
        }.bind(this),
        this.$root.refreshTypesCountInterval
      );
    },

    /**
     *  Start continuous countdown
     */
    secondsUntilRefreshTypesCountInterval() {
      this.countDownInterval = setInterval(
        function () {
          if (this.secondsUntilRefreshTypesCount > 0) {
            this.secondsUntilRefreshTypesCount -= 1000;
          }
        }.bind(this),
        1000
      );
    },

    /**
     * Reset continuous countdown
     */
    resetSecondsUntilRefreshTypesCountInterval() {
      clearInterval(this.countDownInterval);

      this.secondsUntilRefreshTypesCount = this.$root.refreshTypesCountInterval;
      this.secondsUntilRefreshTypesCountInterval();
    },

    /**
     * Business Payment listener
     */
    businessPaymentUpdated() {
      this.updateLoading = false;
      this.claimNextBusinessPaymentInQueue();
    },

    /**
     * Claim open business payment of type for processing
     */
    async claimNextBusinessPaymentInQueue() {
      window.console.log("Business payment: claiming");

      //  Reset list
      this.loading = true;
      this.businessPayment = null;

      let body = JSON.stringify({ claimId: window.getMarkSession("claimId") });

      //  Request
      try {
        const response = await axios.post(
          this.$root.apiUrl + "/businesspayment/claim?type=" + this.type,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );

        this.listLoading = false;
        this.listErrored = false;

        //  Set business payment
        this.businessPayment = response.data;

        //  Set showOnTvCrawler initially
        if (
          this.businessPayment.updatedTs === null &&
          !this.businessPayment.requestedAnonymized
        ) {
          this.businessPayment.showOnTvCrawler = true;
        }

        //  Remember claimId
        window.setMarkSession("claimId", response.data.claimId);
        window.console.log(
          "Business payment: claimed (" + response.data.claimId + ")"
        );
      } catch (error) {
        this.listLoading = false;
        this.listErrored = true;

        //  TODO

        //  No queue: Invoice
        //  Der er ikke flere at behandle

        //  No queue: Betaling
        //  Der er ikke flere at behandle

        //  Queue: Invoice
        //  Alle fakturaer er ved at blive behandlet

        //  Queue: Betalinger
        //  Alle visningsnavne er ved at blive behandlet

        //  Error
        this.$root.infoResponse("Der er intet ledigt i kø til behandling.");

        window.console.log("Business payment: claim failed");
      }
    },

    /**
     * Claim open business payment of type for processing
     */
    async getClaimedBusinessPayment() {
      window.console.log("Business payment: get claimed");

      if (!window.getMarkSession("claimId")) {
        window.console.log(
          "Business payment: get claimed -> claimId missing... start claimNext"
        );

        this.claimNextBusinessPaymentInQueue();
      } else {
        //  Reset list
        this.loading = true;
        this.businessPayment = null;

        //  Request
        try {
          const response = await axios.get(
            this.$root.apiUrl +
              "/businesspayment/" +
              window.getMarkSession("claimId") +
              "/claimed",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.$root.user.authToken,
              },
            }
          );

          this.listLoading = false;
          this.listErrored = false;
          this.businessPayment = response.data;

          window.console.log("Business payment: get success");
        } catch (error) {
          window.setMarkSession("claimId", null);
          this.claimNextBusinessPaymentInQueue();
          this.listLoading = false;
          this.listErrored = true;

          //  TODO

          //  No queue: Invoice
          //  Der er ikke flere at behandle

          //  No queue: Betaling
          //  Der er ikke flere at behandle

          //  Queue: Invoice
          //  Alle fakturaer er ved at blive behandlet

          //  Queue: Betalinger
          //  Alle visningsnavne er ved at blive behandlet

          //  Error
          this.$root.handleErrorResponse(
            error,
            "Vi kunne ikke finde data til godkendelse"
          );

          window.console.log("Business payment: claim failed");
        }
      }
    },

    /**
     * Release business payment
     * TODO : Change to emit (release)
     */
    async releaseBusinessPayment() {
      let claimId = window.getMarkSession("claimId");
      let body = JSON.stringify({ claimId: claimId });

      console.log("Business payment: releasing (" + claimId + ")");

      //  Request
      try {
        await axios.post(
          this.$root.apiUrl +
            "/businesspayment/" +
            this.businessPayment.businessPaymentGuid +
            "/release",
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );
        this.editBusinessPaymentLoading = false;
        this.openBusinessPayment = false;
        this.businessPaymentToEdit = null;
        window.setMarkSession("claimId", null);
        console.log("Business payment: released (" + claimId + ")");
      } catch (e) {
        this.editBusinessPaymentLoading = false;
        this.openBusinessPayment = false;
        this.businessPaymentToEdit = null;
        console.log("Business payment: release failed (" + claimId + ")");
      }
    },
  },
};
</script>