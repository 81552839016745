<template>
  <div class="wrap campaigns">
    <!-- Check permission -->
    <div
      v-if="!this.$root.administrator && !this.$root.customer"
      class="content"
    >
      <!-- Heading -->
      <h2 class="title">SMS</h2>
      <p>Du har ikke adgang til denne side.</p>
    </div>

    <div v-else class="content">
      <div class="heading-wrapper">
        <!-- Heading -->
        <h2 class="title">SMS</h2>
        <!-- Refresh -->
        <label v-if="this.$root.administrator">
          <input
            type="checkbox"
            class="uik-input__input"
            v-model="rapidRefresh"
            v-on:click="changeRefreshInterval()"
          />
          Opdatér hyppigt?
          <div class="check"></div>
        </label>
      </div>

      <!-- Error -->
      <p v-if="this.listErrored">Fejl ...</p>

      <!-- Loading -->
      <p v-else-if="this.listLoading && this.campaigns.length === 0">
        Indlæser ...
      </p>

      <!-- List campaigns -->
      <div
        v-else-if="this.campaigns.length > 0"
        class="row"
        v-bind:class="this.listLoading ? 'loading' : null"
      >
        <div
          v-for="campaign in this.campaigns"
          v-bind:key="campaign.campaignGuid"
          class="col-sm-12 col-md-4"
          v-bind:class="
            campaigns.length === 2
              ? 'col-lg-6'
              : campaigns.length === 4
              ? 'col-lg-3'
              : 'col-lg-4'
          "
        >
          <sms-campaign v-bind:campaign="campaign" />
        </div>
      </div>

      <!-- Not found -->
      <p v-else>Intet fundet ...</p>
    </div>
  </div>
</template>

<script>
import SmsCampaign from "./SmsCampaign.vue";
import axios from "axios";

export default {
  name: "SmsCampaigns",
  components: {
    SmsCampaign,
  },
  data: function () {
    return {
      campaigns: [],
      listErrored: false,
      listLoading: true,
      listChecker: null,
      rapidRefresh: false,
      refreshInterval: 60000, // one minute
    };
  },
  created() {
    //  Log
    window.console.log("Lottery: init");

    //  Get list
    this.getCampaigns();
    this.getCampaignsContinuously();
  },
  destroyed() {
    //  Log
    window.console.log("Lottery: stop refreshing");

    this.campaigns = [];
    clearInterval(this.listChecker);
  },
  methods: {
    /**
     * Get campaigns
     */
    async getCampaigns() {
      this.listLoading = true;

      //  Request
      try {
        const response = await axios.get(this.$root.apiUrl + "/sms/campaigns", {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$root.user.authToken,
          },
        });
        this.listLoading = false;
        this.listErrored = false;
        this.campaigns = response.data;

        //  Sorting by service type
        this.campaigns.sort(function (a, b) {
          if (a.campaign.serviceType < b.campaign.serviceType) {
            return -1;
          } else if (a.campaign.serviceType > b.campaign.serviceType) {
            return 1;
          }
          return 0;
        });

        //  Log
        window.console.log("Lottery: Campaigns refreshed " + this.getTime());
      } catch (error) {
        this.listLoading = false;
        this.listErrored = true;

        //  Error
        this.$root.handleErrorResponse(error);
      }
    },

    /**
     * Get campaigns continuously
     */
    getCampaignsContinuously() {
      //  Log
      window.console.log(
        "Lottery: Started campaigns refreshing cycle. Checking every " +
          this.refreshInterval / 1000 +
          " seconds"
      );

      this.listChecker = setInterval(
        function () {
          //  Skip if error
          if (this.listErrored) {
            //  Log
            window.console.log(
              "Lottery: Stopped campaigns refreshing cycle due to error"
            );
          }

          //  Request
          else {
            //  Log
            window.console.log(
              "Lottery: Refreshing campaigns every " +
                this.refreshInterval / 1000 +
                " seconds"
            );
            this.getCampaigns();
          }
        }.bind(this),
        this.refreshInterval
      );
    },

    /**
     * Handle refresh interval
     */
    changeRefreshInterval() {
      //  Stop refreshing
      clearInterval(this.listChecker);

      //  Set to rapid
      if (this.rapidRefresh === false) {
        //  Log
        window.console.log(
          "Lottery: Winners processing. Refreshing rapidly every 5 seconds"
        );

        this.rapidRefresh = true;
        this.refreshInterval = 5000; // five seconds
      }

      //  Set to normal
      else {
        //  Log
        window.console.log(
          "Lottery: Winners processed. Refreshing normally every 60 seconds"
        );

        this.rapidRefresh = false;
        this.refreshInterval = 60000; // one minute
      }

      //  Restart refreshing
      this.getCampaignsContinuously();
    },

    /**
     * Get current time
     */
    getTime: function () {
      var date = new Date(),
        year = date.getFullYear(),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2),
        hours = ("0" + date.getHours()).slice(-2),
        minutes = ("0" + date.getMinutes()).slice(-2),
        seconds = ("0" + date.getSeconds()).slice(-2);

      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
  },
};
</script>
