//  Set cookie
window.setCookie = function(k, v) {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);

    const expires = "expires=" + d.toGMTString();

    document.cookie =
        k + "=" + v + "; " + expires + "; path=/; samesite=none; secure;";
};

//  Get cookie
window.getCookie = function(k) {
    const start = document.cookie.indexOf(k + "="),
        len = start + k.length + 1;
    let end = document.cookie.indexOf(";", len);
    if (!start && k !== document.cookie.substring(0, k.length)) {
        return null;
    }

    if (start === -1) return null;

    if (end === -1) end = document.cookie.length;

    return unescape(document.cookie.substring(len, end));
};

//  Delete cookie
window.deleteCookie = function(k) {
    document.cookie =
        k + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT; samesite=none; secure;";
};

//  Set mark by localStorage with fallback to cookie
window.setMark = function(k, v) {
    v = JSON.stringify(v) || 1;

    try {
        if (typeof localStorage !== "undefined") {
            localStorage.setItem(k, v);
        } else {
            window.setCookie(k, v);
        }
    } catch (err) {
        window.console.log(err);
        window.setCookie(k, v);
    }
};

//  Get mark by localStorage with fallback to cookie
window.getMark = function(k) {
    let v;

    try {
        if (typeof localStorage !== "undefined") {
            v = localStorage.getItem(k);
        } else {
            v = window.getCookie(k);
        }
    } catch (err) {
        window.console.log(err);
        v = window.getCookie(k);
    }

    return JSON.parse(v);
};

//  Set mark by sessionStorage with fallback to cookie
window.setMarkSession = function(k, v) {
    v = JSON.stringify(v) || 1;

    try {
        if (typeof sessionStorage !== "undefined") {
            sessionStorage.setItem(k, v);
        } else {
            window.setCookie(k, v);
        }
    } catch (err) {
        window.console.log(err);
        window.setCookie(k, v);
    }
};

//  Get mark by sessionStorage with fallback to cookie
window.getMarkSession = function(k) {
    let v;

    try {
        if (typeof sessionStorage !== "undefined") {
            v = sessionStorage.getItem(k);
        } else {
            v = window.getCookie(k);
        }
    } catch (err) {
        window.console.log(err);
        v = window.getCookie(k);
    }

    return JSON.parse(v);
};

//  Store entities
window.entityStorage = [];

//  Remove entity from storage
window.removeEntityStorage = function(guid) {
    delete window.entityStorage[guid];
};

//  Open popup
window.openPopup = function(url, width, height) {

    width = width || 950;
    height = height || 825;

    let left = 50; // (screen.width - width) / 2;
    let top = 10; // (screen.height - height) / 2;

    var popup_window = window.open(url, "gatewayWindow", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, height=" + height + ", width=" + width + ", left=" + left + ", top=" + top);
    try {
        popup_window.focus();
    } catch (e) {
        alert('Please allow popup');
    }

};

//  Extend Date
Date.prototype.getWeekNumber = function() {
    var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
};

//  Extend Date
Date.prototype.yyyymmdd = function() {
    return this.getFullYear() + "-" + ("0" + (this.getMonth() + 1)).slice(-2) + '-' + ("0" + this.getDate()).slice(-2);
};

//  Filter array
window.onlyUnique = function(value, index, self) {
    return self.indexOf(value) === index;
}