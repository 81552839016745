<template>
  <div class="wrap corrections">
    <!-- Check permission -->
    <div
      v-if="!this.$root.administrator && !this.$root.customer"
      class="content"
    >
      <!-- Heading -->
      <h2 class="title">Korrektioner</h2>
      <p>Du har ikke adgang til denne side.</p>
    </div>

    <div v-else class="content">
      <div class="heading-wrapper">
        <!-- Heading -->
        <h2 class="title">Korrektioner</h2>
        <p class="monospace">
          Sum af alt: {{ getSumOfCorrections | formatAmount }} DKK
        </p>
        <p class="monospace">
          Sum af aktive: {{ getSumOfEnabledCorrections | formatAmount }} DKK
        </p>
      </div>

      <!-- Table -->
      <div class="uik-entity-list correction-list">
        <table>
          <!-- Table header -->
          <thead>
            <tr>
              <th class="manage-column column-created" style="width: 190px">
                Tidspunkt
              </th>
              <th class="manage-column column-amount">Beløb</th>
              <th class="manage-column column-type">Type</th>
              <th class="manage-column column-description">Beskrivelse</th>
              <th class="manage-column column-action"></th>
            </tr>
          </thead>

          <!-- Error -->
          <tbody v-if="this.listErrored">
            <tr>
              <td colspan="5" class="text-center">Fejl ...</td>
            </tr>
          </tbody>

          <!-- Loading -->
          <tbody v-else-if="this.listLoading">
            <tr>
              <td colspan="5" class="text-center">Indlæser ...</td>
            </tr>
          </tbody>

          <!-- Table body -->
          <tbody v-else>
            <!-- Register new correction -->
            <tr>
              <td class="manage-column column-created"></td>
              <td class="manage-column column-amount">
                <input
                  type="number"
                  class="uik-input__input align-right"
                  placeholder="Beløb"
                  v-model="newCorrection.amount"
                />
              </td>
              <td class="manage-column column-type">
                <select
                  id="filter_yAxesScale"
                  class="uik-input__input"
                  v-model="newCorrection.type"
                >
                  <option v-bind:value="'EVENT_PAYMENT'">Event payment</option>
                  <option v-bind:value="'BUSINESS_PAYMENT'">
                    Business payment
                  </option>
                  <option v-bind:value="'CARD'">Betalingskort</option>
                  <option v-bind:value="'MOBILE_PAY_ONLINE'">
                    MobilePay Online
                  </option>
                  <option v-bind:value="'MOBILE_PAY_MYSHOP'">
                    MobilePay MyShop
                  </option>
                  <option v-bind:value="'BETALINGSSERVICE_SINGLE'">
                    Betalingsservice
                  </option>
                  <option v-bind:value="'SMS'">SMS Donation</option>
                  <option v-bind:value="'SMS_LOTTERY'">SMS Lodtrækning</option>
                </select>
              </td>
              <td class="manage-column column-description">
                <input
                  type="text"
                  class="uik-input__input"
                  placeholder=""
                  v-model="newCorrection.description"
                  v-on:keyup.enter="insertCorrection()"
                />
              </td>
              <td class="manage-column column-action">
                <button
                  type="button"
                  class="uik-btn__base btn btn-primary btn-small"
                  v-on:click="insertCorrection()"
                >
                  Registrér
                </button>
              </td>
            </tr>

            <!-- Loop corrections -->
            <tr
              v-for="correction in this.corrections"
              v-bind:key="correction.correctionGuid"
              v-bind:class="{
                green: correction.enabled === true,
                grey: correction.enabled === false,
              }"
            >
              <td
                class="manage-column column-created"
                v-bind:inner-html.prop="correction.createdTs | formatTime"
              ></td>
              <td class="manage-column column-amount align-right">
                <span
                  v-bind:title="
                    $root.user.userGuid === correction.userGuid
                      ? 'Created by you'
                      : 'Created by someone else'
                  "
                  v-if="correction.amount < 0"
                  class="negative"
                >
                  {{ correction.amount | formatAmount }}
                </span>
                <span
                  v-bind:title="
                    $root.user.userGuid === correction.userGuid
                      ? 'Created by you'
                      : 'Created by someone else'
                  "
                  v-else
                >
                  {{ correction.amount | formatAmount }}
                </span>
              </td>
              <td class="manage-column column-type">
                {{ correction.type | formatPaymentMethodType }}
              </td>
              <td class="manage-column column-description">
                <input
                  type="text"
                  class="uik-input__input"
                  v-model="correction.description"
                />
              </td>
              <td class="manage-column column-action">
                <!-- Actions -->
                <button
                  type="button"
                  class="uik-btn__base btn btn-small btn-disable"
                  v-if="correction.enabled"
                  v-on:click="updateCorrectionState(correction, false)"
                >
                  Deaktivér
                </button>
                <button
                  type="button"
                  class="uik-btn__base btn btn-small btn-enable"
                  v-else-if="!correction.enabled"
                  v-on:click="updateCorrectionState(correction, true)"
                >
                  Aktivér
                </button>
                <button
                  type="button"
                  class="uik-btn__base btn btn-small"
                  v-on:click="updateCorrection(correction)"
                >
                  Opdatér
                </button>
                <button
                  type="button"
                  class="btn btn-icon btn-delete"
                  title="Slet"
                  v-on:click="deleteCorrection(correction)"
                >
                  <i class="fa fa-times"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { formats } from "../../mixins/formats.js";
import axios from "axios";

export default {
  mixins: [formats],
  data: function () {
    return {
      corrections: [],
      listErrored: false,
      listLoading: true,
      newCorrection: {
        type: "EVENT_PAYMENT",
      },
    };
  },
  computed: {
    getSumOfCorrections() {
      let sum = 0;

      if (this.corrections.length > 0) {
        for (var i = 0; i < this.corrections.length; i++) {
          sum = sum + parseInt(this.corrections[i].amount);
        }
      }

      //  Account for newly created corrections
      if (this.newCorrection && this.newCorrection.amount) {
        sum = sum + parseInt(this.newCorrection.amount);
      }

      return sum;
    },
    getSumOfEnabledCorrections() {
      let sum = 0;

      if (this.corrections.length > 0) {
        for (var i = 0; i < this.corrections.length; i++) {
          if (this.corrections[i].enabled) {
            sum = sum + parseInt(this.corrections[i].amount);
          }
        }
      }

      return sum;
    },
  },
  created() {
    //  Get list
    this.getCorrections();
  },
  methods: {
    /**
     * Get corrections
     */
    async getCorrections() {
      //  Reset list
      this.listLoading = true;
      this.corrections = [];

      //  Request
      try {
        const response = await axios.get(this.$root.apiUrl + "/corrections", {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$root.user.authToken,
          },
        });
        this.listLoading = false;
        this.listErrored = false;
        this.corrections = response.data;
      } catch (error) {
        this.listLoading = false;
        this.listErrored = true;

        //  Error
        this.$root.handleErrorResponse(error);
      }
    },

    /**
     * Insert correction
     */
    async insertCorrection() {
      //  Validate
      if (!this.newCorrection.amount) {
        this.$root.errorResponse("Missing amount");
        return;
      } else if (!this.newCorrection.description) {
        this.$root.errorResponse("Missing description");
        return;
      } else if (!this.newCorrection.type) {
        this.newCorrection.type = "Event";
      }

      //  Body
      let body = JSON.stringify(this.newCorrection);

      //  Request
      try {
        const response = await axios.post(
          this.$root.apiUrl + "/correction",
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );
        //  Reset
        this.newCorrection = {};

        //  Refresh list
        this.getCorrections();

        //  Success
        this.$root.successResponse("Created", response);
      } catch (error) {
        //  Error
        this.$root.handleErrorResponse(error);
      }
    },

    /**
     * Update correction
     * @param {object} correction
     */
    async updateCorrection(correction) {
      //  Validate
      if (!correction.amount) {
        this.$root.errorResponse("Missing amount");
        return;
      } else if (!correction.description) {
        this.$root.errorResponse("Missing description");
        return;
      }

      //  Body
      let body = JSON.stringify(correction);

      //  Request
      try {
        const response = await axios.put(
          this.$root.apiUrl + "/correction/" + correction.correctionGuid,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );
        //  Append enabled state
        correction.enabled = response.data.enabled;

        //  Success
        this.$root.successResponse("Updated", response);
      } catch (error) {
        //  Error
        this.$root.handleErrorResponse(error);
      }
    },

    /**
     * Update correction state
     * @param {object} correction
     * @param {boolean} state
     */
    updateCorrectionState(correction, state) {
      //  Set new state
      if (state === false || state === true) {
        correction.enabled = state;
      }

      //  Update
      this.updateCorrection(correction);
    },

    /**
     * Delete correction
     * @param {object} correction
     */
    async deleteCorrection(correction) {
      //  Verify
      if (confirm("Du er ved at slette. Er du sikker?")) {
        //  Request
        try {
          const response = await axios.delete(
            this.$root.apiUrl + "/correction/" + correction.correctionGuid,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.$root.user.authToken,
              },
            }
          );
          //  Refresh list
          this.getCorrections();

          //  Success
          this.$root.successResponse("Deleted", response);
        } catch (error) {
          //  Error
          this.$root.handleErrorResponse(error);
        }
      }
    },
  },
};
</script>
