<template>
  <div
    class="campaign-wrapper"
    v-bind:class="{
      green: campaign.campaign.state === 'Active',
      blue: campaign.campaign.state === 'Stopped',
    }"
  >
    <!-- Service Type : SINGLE -->
    <div
      v-if="campaign.campaign.serviceType === 'SINGLE'"
      class="campaign-header"
    >
      <span v-if="campaign.campaign.state === 'Active'">Aktiv donation</span>
      <span v-else-if="campaign.campaign.state === 'Stopped'"
        >Stoppet donation</span
      >
      <span v-else>{{ campaign.campaign.state }}</span>
    </div>
    <!-- Service Type : LOTTERY -->
    <div
      v-else-if="campaign.campaign.serviceType === 'LOTTERY'"
      class="campaign-header"
    >
      <span v-if="campaign.campaign.state === 'Active'">Aktiv lodtrækning</span>
      <span v-else-if="campaign.campaign.state === 'Stopped'"
        >Stoppet lodtrækning</span
      >
      <span v-else>{{ campaign.campaign.state }}</span>
      <!-- Actions -->
      <button
        v-if="campaign.campaign.state === 'Stopped' && this.$root.administrator"
        class="uik-btn__base btn btn-small btn-approve"
        v-on:click="startDrawWinnerOfCampaign(campaign)"
      >
        Træk vinder(e)
      </button>
      <button
        v-if="campaign.campaign.state === 'Stopped' && this.$root.administrator"
        class="uik-btn__base btn btn-small btn-start"
        v-on:click="startCampaign(campaign)"
      >
        Start igen
      </button>
      <button
        v-else-if="
          campaign.campaign.state === 'Active' && this.$root.administrator
        "
        class="uik-btn__base btn btn-small btn-reject"
        v-on:click="stopCampaign(campaign)"
      >
        Stop
      </button>
    </div>
    <div class="campaign-body">
      <!-- Name -->
      <h3 v-if="campaign.campaign.campaignName">
        {{ campaign.campaign.campaignName }}
      </h3>
      <h3 v-else>Unavngiven</h3>

      <!-- Details -->
      <div class="campaign-detail">
        <div class="flex-container flex-space-between">
          <span>Keywords</span>
          <span class="text-align-right">{{ getKeywordsString }}</span>
        </div>
        <div class="flex-container flex-space-between">
          <span>Pris pr. SMS</span>
          <span class="text-align-right"
            >{{ campaign.campaign.amount | formatAmount }}
            {{ campaign.campaign.currencyCode }}</span
          >
        </div>
        <div
          v-if="campaign.campaign.serviceType === 'LOTTERY'"
          class="flex-container flex-space-between"
        >
          <span>Antal vindere</span>
          <span class="text-align-right">{{
            campaign.campaign.winnerCount
          }}</span>
        </div>
        <div
          v-if="campaign.campaign.serviceType === 'LOTTERY'"
          class="flex-container flex-space-between"
        >
          <span>Max deltagelse</span>
          <span class="text-align-right">{{
            campaign.campaign.maxParticipations
          }}</span>
        </div>
      </div>
      <!-- Timestamps -->
      <div
        v-if="campaign.campaign.startTs || campaign.campaign.endTs"
        class="campaign-detail"
      >
        <div
          v-if="campaign.campaign.startTs"
          class="flex-container flex-space-between"
        >
          <span>Start</span>
          <span
            v-bind:inner-html.prop="campaign.campaign.startTs | formatTime"
            class="text-align-right"
          ></span>
        </div>
        <div
          v-if="campaign.campaign.stoppedTs"
          class="flex-container flex-space-between"
        >
          <span>Stoppet</span>
          <span
            v-bind:inner-html.prop="campaign.campaign.stoppedTs | formatTime"
            class="text-align-right"
          ></span>
        </div>
        <div
          v-else-if="campaign.campaign.endTs"
          class="flex-container flex-space-between"
        >
          <span>Slut</span>
          <span
            v-bind:inner-html.prop="campaign.campaign.endTs | formatTime"
            class="text-align-right"
          ></span>
        </div>
      </div>

      <!-- Tickets -->
      <div class="campaign-detail">
        <div class="flex-container flex-space-between">
          <span>Indsamlet beløb</span>
          <span class="text-align-right"
            >{{ campaign.totalAmount | formatAmount }}
            {{ campaign.campaign.currencyCode }}</span
          >
        </div>
        <div class="flex-container flex-space-between">
          <span>Antal SMS'er</span>
          <span class="text-align-right">{{ campaign.ticketCount }} stk.</span>
        </div>
      </div>

      <!-- Winners -->
      <sms-winners
        ref="WinnersList"
        v-if="campaign.campaign.state === 'Stopped'"
        v-bind:campaign="campaign"
        v-on:winnersFound="winnersFound"
      />
    </div>
  </div>
</template>

<script>
import SmsWinners from "./SmsWinners.vue";
import { formats } from "../../mixins/formats.js";
import axios from "axios";

export default {
  name: "SmsCampaign",
  components: {
    SmsWinners,
  },
  mixins: [formats],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getKeywordsString: function () {
      //  Return a simple string
      if (this.campaign.keywords.length === 1) {
        return '"' + this.campaign.keywords[0].toUpperCase() + '"';
      }

      //  Return a string of keywords
      else {
        let string = "";
        for (var i = 0; i < this.campaign.keywords.length; i++) {
          string =
            '"' + this.campaign.keywords[i].toUpperCase() + '", ' + string;
        }

        return string;
      }
    },
  },
  methods: {
    /**
     * Start
     */
    async startCampaign(campaign) {
      //  Request
      try {
        const response = await axios.post(
          this.$root.apiUrl +
            "/sms/campaign/" +
            campaign.campaign.campaignGuid +
            "/Start",
          null,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );
        campaign.campaign.state = "Active";
        this.$forceUpdate();

        //  Success
        this.$root.successResponse("Started", response);
      } catch (error) {
        //  Error
        this.$root.handleErrorResponse(error);
      }
    },

    /**
     * Stop
     */
    async stopCampaign(campaign) {
      //  Verify
      if (confirm("Du er ved at stoppe denne lodtrækning. Er du sikker?")) {
        //  Request
        try {
          const response = await axios.post(
            this.$root.apiUrl +
              "/sms/campaign/" +
              campaign.campaign.campaignGuid +
              "/Stop",
            null,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.$root.user.authToken,
              },
            }
          );
          campaign.campaign.state = "Stopped";
          this.$forceUpdate();

          //  Success
          this.$root.successResponse("Stopped", response);
        } catch (error) {
          //  Error
          this.$root.handleErrorResponse(error);
        }
      }
    },

    /**
     * Draw winners
     */
    async startDrawWinnerOfCampaign(campaign) {
      //  Request
      try {
        const response = await axios.post(
          this.$root.apiUrl +
            "/sms/campaign/" +
            campaign.campaign.campaignGuid +
            "/DrawWinner",
          null,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );

        //  Emit to parent component
        this.$emit("winnersProcessing");

        //  Success
        this.$root.successResponse("Processing", response);
      } catch (error) {
        //  Error
        this.$root.handleErrorResponse(error);
      }
    },

    /**
     * Handle found winners
     */
    winnersFound() {
      //  Emit to parent component
      this.$emit("winnersProcessed");

      //  Force update component to remove buttons and change title
      this.$forceUpdate();
    },
  },
};
</script>
