export const formats = {
    filters: {

        /**
         * Lowercase a string
         * @param {string} string 
         */
        toLowerCase: function(string) {

            return string.toLowerCase();
        },

        /**
         * Uppercase a string
         * @param {string} string 
         */
        toUpperCase: function(string) {

            return string.toUpperCase();
        },

        /**
         * Format date
         * <span v-bind:inner-html.prop="elem.chargedTs | formatDate"></span>
         * @param {string} timestamp 
         */
        formatDate: function(timestamp) {

            //  2020-01-01
            if (timestamp) {
                return '<span class="Ts">' + timestamp.substring(0, 10) + "</span>";
            }
            return "";
        },

        /**
         * Format timestamp
         * <span v-bind:inner-html.prop="elem.chargedTs | formatTime"></span>
         * @param {string} timestamp 
         */
        formatTime: function(timestamp) {
            //  2020-01-01
            //  00:00:00
            if (timestamp) {
                return (
                    '<span class="Ts" title="' +
                    timestamp.substring(0, 10) + ' ' + timestamp.substring(11, 19) +
                    '">' +
                    timestamp.substring(0, 10) +
                    " <span>" +
                    timestamp.substring(11, 19) +
                    "</span></span>"
                );
            }
            return "";
        },

        /**
         * Format timestamp
         * <span v-bind:inner-html.prop="elem.chargedTs | formatHour"></span>
         * @param {string} timestamp 
         */
        formatHour: function(timestamp) {
            //  2020-01-01
            //  00:00:00
            if (timestamp) {
                return (
                    '<span class="Ts" title="' +
                    timestamp.substring(0, 10) + ' ' + timestamp.substring(11, 19) +
                    '">' +
                    timestamp.substring(11, 19) +
                    "</span>"
                );
            }
            return "";
        },

        /**
         * Format msisdn
         * @param {string} msisdn 
         */
        formatMsisdn: function(msisdn) {

            //  4512345678
            if (msisdn) {
                return '<span class="msisdn">+' + msisdn.substring(0, 2) + " " + msisdn.substring(2, 4) + " " + msisdn.substring(4, 6) + " " + msisdn.substring(6, 8) + " " + msisdn.substring(8, 10) + "</span>";
            }
            return "";
        },

        /**
         * Format boolean
         * @param {boolean} boolean 
         */
        formatBoolean: function(boolean) {

            if (boolean === true) {
                return 'Yes';
            } else {
                return 'No';
            }
        },

        /**
         * Format amount
         * @param {integer} number 
         */
        formatAmount: function(number) {

            var n = parseFloat(number);

            return n.toLocaleString('da-DK', {
                maximumFractionDigits: '2'
            });
        },

        /**
         * Format double
         * @param {integer} number 
         */
        formatDouble: function(number) {

            var n = parseFloat(number);

            return n.toFixed(1);
        },

        /**
         * Format status line type
         * @param {string} type 
         */
        formatPaymentMethodType: function(type) {

            if (type === 'EVENT_PAYMENT') {
                return 'Event payment';
            } else if (type === 'BUSINESS_PAYMENT') {
                return 'Business payment';
            } else if (type === 'CARD') {
                return 'Kort';
            } else if (type === 'INVOICE') {
                return 'Faktura';
            } else if (type === 'MOBILE_PAY_ONLINE') {
                return 'MobilePay Online';
            } else if (type === 'MOBILE_PAY_MYSHOP') {
                return 'MobilePay MyShop';
            } else if (type === 'BETALINGSSERVICE_SINGLE') {
                return 'Betalingsservice';
            } else if (type === 'SMS') {
                return 'SMS Donation';
            } else if (type === 'SMS_LOTTERY') {
                return 'SMS Lodtrækning';
            }

            return type;
        },
    }
}