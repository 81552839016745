<template>
  <div>
    <button type="button" v-on:click="sendLogout">Log ud</button>
  </div>
</template>

<script>
export default {
  methods: {
    sendLogout: function () {
      this.$root.logOut();
    },
  },
};
</script>
