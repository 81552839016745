<template>
  <div id="app">
    <!-- Login -->
    <div v-if="this.loginInvalid || this.$root.user.authToken === null">
      <login v-on:loggedIn="whenloggedIn" />
    </div>

    <!-- Router -->
    <div v-else-if="this.$root.store.event">
      <navbar-top />
      <navbar-left />
      <router-view />
    </div>
  </div>
</template>

<script>
import Login from "./components/account/Login";
import NavbarTop from "./components/nav/NavbarTop.vue";
import NavbarLeft from "./components/nav/NavbarLeft.vue";
import axios from "axios";

export default {
  name: "app",
  components: {
    Login,
    NavbarTop,
    NavbarLeft,
  },
  data: function () {
    return {
      loginInvalid: false,
    };
  },
  beforeCreate() {
    /**
     * Check authExp from browser session for an initial login validation
     */
    var nowInSeconds = Math.round(new Date().getTime() / 1000);

    let authExp = window.getMark("authExp");
    if (authExp && nowInSeconds > authExp) {
      this.loginInvalid = true;
    }
  },
  created() {
    this.getEvent();
  },
  methods: {
    /**
     * Get event as validation of user
     */
    async getEvent() {
      window.console.log("event initialising");

      //  Request event
      if (this.$root.user.authToken) {
        try {
          const response = await axios.get(this.$root.apiUrl + "/event", {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          });
          this.$root.store.event = response.data;
          window.console.log("event initialised");
          this.$forceUpdate();
        } catch (error) {
          //  Error
          this.$root.handleErrorResponse(error);
        }
      }

      //  Log
      else {
        window.console.log("user not logged in");
      }
    },

    /**
     * This is need to refresh the UI once the login is validated
     */
    whenloggedIn: function () {
      this.getEvent();
      this.$forceUpdate();
    },
  },
};
</script>
