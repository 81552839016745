<template>
  <div>
    <div class="of-modal">
      <!-- Header -->
      <div class="modal-header">
        <h2>Rediger</h2>
        <div class="right-aligned">
          <button
            type="button"
            class="of-modal-close"
            tabindex="-1"
            v-on:click.prevent="closeModal"
          >
            <i class="dashboard-icon icon-close"></i>
          </button>
        </div>
      </div>

      <!-- Body -->
      <div class="modal-content">
        <slot></slot>
      </div>
    </div>
    <div class="of-modal-bg"
      v-on:click.prevent="closeModal"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  methods: {
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>
